import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-admin-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class AdminActivityComponent implements OnInit {
  public isLoading = false;
  events: any[] = []

  constructor(
    private title: Title,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Admin activity`);
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.events = await lastValueFrom(this.adminService.getActivity())
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }

  }

}
