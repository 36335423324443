<div class="container-fluid">

    <div class="d-flex justify-content-between">
        <h1>Organizations</h1>
        <a mat-icon-button [routerLink]="[]" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </a>
    </div>


    <div>
        <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
            <thead>
                <td>Name</td>
                <td>Erstelldatum</td>
                <td>Location</td>
                <td>E-Mail</td>
            </thead>

            <tbody>

                <tr *ngFor="let item of items">
                    <td>
                        <a routerLink="/main/admin/organizations/{{item.uuid}}">
                            {{item.name}}
                        </a>
                    </td>
                    <td>{{item.created_at | humanDatePipe}}</td>
                    <td>{{item.zip }} {{item.location}}</td>
                    <td>{{item.email}}</td>
                </tr>

            </tbody>
        </table>
    </div>
</div>
