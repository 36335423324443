import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'projects/webapp/app/services/dashboard.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {
  isLoading = false;
  items: any[]= []

  constructor(
    private title: Title,
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.title.setTitle('Movline24: Meine Umzüge');
  }

  async ngOnInit() {
  this.refresh();
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      this.items = await lastValueFrom(this.dashboardService.getOrders())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }
}
