import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { InquiriesService } from 'projects/webapp/app/services/inquiries.service';
import { IDetailedOffer } from 'projects/webapp/app/interfaces/offer.interface';


@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {

  public isLoading = false;
  item: IDetailedOffer | null = null
  offerUUID: string | null = null;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
  ) { }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Details der Anfrage`);
    this.route.params.subscribe(params => {
      if (!!params && params['offerUUID']) {
        this.offerUUID = params['offerUUID'];
        this.refresh();
      }
    });
  }

  async refresh() {
    try {
      this.spinnerService.show();
      if (this.offerUUID) {
        // this.item = await lastValueFrom(this.inquiriesService.getOffer(this.offerUUID))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }
}
