import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { lastValueFrom } from "rxjs";
import { DashboardService, IRating } from "../../services/dashboard.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-rating-card",
  templateUrl: 'rating-card.component.html',
  styleUrls: ['./rating-card.component.scss']

})
export class RatingCardComponent implements OnInit {
  items: IRating[]= [
  ]

  isLoading = false;
  environment = environment;
  constructor(
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService,
  ) { }

  async ngOnInit() {
    this.refresh();
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      this.items = await lastValueFrom(this.dashboardService.getMyRatings());
      this.items = this.items.slice(0, 5);
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }
}
