import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'projects/webapp/app/services/auth.service';
// import { FileHandle } from 'projects/webapp/app/services/dragDrop.directive';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';


@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent implements OnInit {

  ownOrganization: IOrganization | null = null;
  ownOrganizationLogo: any = null;


  organizationForm = new FormGroup({
    company_name: new FormControl('', Validators.required),

    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),

    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    website: new FormControl(''),

    vat_number: new FormControl(''),
    iban_number: new FormControl(''),

    address: new FormControl('', Validators.required),
    zip: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
  });

  constructor(
    public authService: AuthService,
    // public clientService: ClientsService,
    public usersService: UsersService,
    private organizationService: OrganizationsService,
    public dialog: MatDialog,
    private title: Title,
    // private translate: TranslateService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService
  ) {
    this.title.setTitle('Movline24: Meine Firma')
  }

  async ngOnInit() {
    await this.refresh()
  }

  async refresh() {
    this.spinnerService.show()
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    if (!!this.ownOrganization) {
      this.organizationForm.patchValue(this.ownOrganization);
    }

    this.spinnerService.hide()
  }

  async submitOrganization() {
    this.organizationForm.markAllAsTouched()
    if (this.organizationForm.valid) {
      const response = await this.organizationService.updateOwnOrganization(this.organizationForm.value as IOrganization).toPromise()
      console.log(response);
      this.toastrService.success('Organisation aktualisiert!');
      await this.refresh()
    }
  }

  // filesDropped(files: FileHandle[] | any): void {
  //   this.files = files;

  //   if (this.files) {

  //     this.files.forEach(async file => {
  //       const fileName = file.name || '';
  //       const formData = new FormData();
  //       let blob = await fetch(file.data).then(r => r.blob());
  //       console.log(blob)
  //       formData.append("file", blob as any);
  //       formData.append('name', fileName);
  //       const result = await lastValueFrom(this.organizationService.updateLogo(formData));

  //       console.log(result);
  //       this.ownOrganizationLogo = result

  //     });
  //   }
  // }
  // async onFileSelected(event: any) {
  //   const file: File = event.target.files[0];

  //   if (file) {

  //     const fileName = file.name;

  //     const formData = new FormData();

  //     formData.append("file", file);
  //     formData.append("name", fileName);

  //     const result = await lastValueFrom(this.organizationService.updateLogo(formData));
  //     this.ownOrganizationLogo = result
  //   }
  // }



}
