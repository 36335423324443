import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { environment } from 'projects/webapp/app/environments/environment';
import { outputErrors } from 'projects/webapp/app/utils';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';

@Component({
  selector: 'app-inquiry-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class InquiryStep3Component implements OnInit {

  public draftInquiryForm = new FormGroup({

    what_to_clean_all_rooms: new FormControl(false, Validators.required),
    what_to_clean_kitchen_toilet: new FormControl(false, Validators.required),
    what_to_clean_attic: new FormControl(false, Validators.required),
    what_to_clean_winter_garden: new FormControl(false, Validators.required),
    what_to_clean_balcony: new FormControl(false, Validators.required),
    what_to_clean_basement: new FormControl(false, Validators.required),

    special_clean_build_in_wardrobes: new FormControl(false, Validators.required),
    special_clean_hardened_dirt: new FormControl(false, Validators.required),
    special_clean_mold: new FormControl(false, Validators.required),
    special_clean_has_stuff: new FormControl(false, Validators.required),
    special_clean_has_washing_machine: new FormControl(false, Validators.required),
    special_clean_has_pets: new FormControl(false, Validators.required),
    special_clean_has_cheminee: new FormControl(false, Validators.required),
    special_clean_shampoo_carpet: new FormControl(false, Validators.required),
    special_clean_shampoo_carpet_number: new FormControl(''),

    number_of_showers: new FormControl('1'),
    number_of_toilets: new FormControl('1'),
    number_of_sinks: new FormControl('1'),

    window_normal: new FormControl('1'),
    window_wall: new FormControl('1'),
    window_doors: new FormControl('1'),

    service: new FormControl('1', Validators.required),
  });

  isLoading = false;
  currentUUID: string = ''

  environment = environment;

  selectors = {
    number_of_showers: {
      fieldName: 'Duschen / Badewannen',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    number_of_toilets: {
      fieldName: 'Toiletten',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    number_of_sinks: {
      fieldName: 'Lavabos',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },

    window_normal: {
      fieldName: 'Normale Fenster',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    window_wall: {
      fieldName: 'Fensterwände',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    window_doors: {
      fieldName: 'Fenstertüren',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
  }

  constructor(
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private publicInquiryService: PublicInquiryService) {
  }

  async ngOnInit() {

    this.draftInquiryForm.get('service')?.valueChanges.subscribe((value) => {
      const service = this.draftInquiryForm.get('service');
      if (service && service.value) {
        this.publicInquiryService.inquiryServiceType$.next(service.value as InquiryServiceType)
      }
    });

    const currentUUID = localStorage.getItem('currentInquiryUUID');
    if (currentUUID) {
      this.currentUUID = currentUUID;
      const response = await lastValueFrom(this.publicInquiryService.getEnquiry(this.currentUUID))
      this.draftInquiryForm.patchValue(response)
      if (response.service == InquiryServiceType.MOVE) {
        console.log('going to step 4 as no cleaning needed...')
        this.router.navigate(['/inquiry/4'])
      }

    } else {
      this.router.navigate(['/inquiry/1'])
    }
  }

  async onSubmit() {
    this.draftInquiryForm.markAllAsTouched()
    if (!this.draftInquiryForm.valid) {
      outputErrors(this.draftInquiryForm)
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
    return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.updateEnquiry(this.currentUUID, this.draftInquiryForm.value))
      this.spinnerService.hide()

      if (response.service == InquiryServiceType.CLEAN) {
        console.log('we are only in clean mode -> going to step 6')
        this.router.navigate(['/inquiry/6']);
      } else {
        this.router.navigate(['/inquiry/4'])
      }
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.isLoading = false;
    }
  }


}
