import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { IAdminDetailedOffer } from 'projects/webapp/app/interfaces/offer.interface';
import { environment } from 'projects/webapp/app/environments/environment';
import { InquiryStatus } from 'projects/webapp/app/interfaces/inquiry.interface';


@Component({
  selector: 'app-admin-offers',
  templateUrl: './admin-offers.component.html',
  styleUrls: ['./admin-offers.component.scss']
})
export class AdminOffersComponent implements OnInit {

  // offerTypeFilter = 'active'

  public isLoading = false;
  items: IAdminDetailedOffer[] = []

  environment = environment;

  constructor(
    private title: Title,
    private router: Router,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Admin Offers`);
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      let params: any = {}
      // if (this.offerTypeFilter == 'active') {
      //   params['status'] = [
      //     InquiryStatus.OPEN,
      //     InquiryStatus.ACCEPTED,
      //     InquiryStatus.OPEN_FOR_BIDS,
      //     InquiryStatus.WAITING_FOR_ACCEPTANCE,
      //     InquiryStatus.ASSIGNED,
      //   ]
      // }

      this.items = await lastValueFrom(this.adminService.getOffers(params))
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  applyFilter() {
    this.refresh()
  }

  onClickDetails(uuid: string) {
    this.router.navigate(['/main/admin/offers/' + uuid])
  }

}
