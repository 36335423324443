<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
        <h1>Über Movline24</h1>
      </div>
      <main>
        <h2>Die Verbindung zwischen Umzugsunternehmen und Kunden</h2>

        <p>Willkommen auf unserer Plattform, auf der wir die Lücke zwischen Umzugsunternehmen und Kunden schliessen, die
          zuverlässige, effiziente und stressfreie Umzugsdienste suchen. Wir verstehen, dass ein Umzug eine
          anspruchsvolle
          Aufgabe sein kann und die Suche nach dem richtigen Umzugsunternehmen zu einem herausfordernden Unterfangen
          werden
          kann. Deshalb haben wir eine nahtlose, benutzerfreundliche Plattform geschaffen, um den Prozess für
          Umzugsunternehmen und Kunden zu vereinfachen.</p>

        <h2>Die Herausforderungen des Umzugs lösen</h2>

        <p>Umzüge, ob in der Nähe oder in die Ferne, werden oft mit Stress und vielen logistischen Herausforderungen
          verbunden. Kunden haben oft Schwierigkeiten, seriöse Umzugsunternehmen zu finden, die wettbewerbsfähige Preise
          und
          zuverlässige Dienstleistungen anbieten. Umzugsunternehmen stehen wiederum vor der Herausforderung, potenzielle
          Kunden zu erreichen und deren Angebotsanfragen, Buchungen und Zahlungen effizient zu verwalten.</p>

        <h2>So funktioniert unsere Dienstleistung</h2>

        <ol>
          <li>Angebot anfordern: Kunden können damit beginnen, ihre Umzugsanforderungen zu beschreiben, wie
            beispielsweise die
            zu transportierenden Gegenstände, die Entfernung und das gewünschte Datum. Diese Informationen sind für
            Umzugsunternehmen entscheidend, um genaue Angebote abzugeben.</li>
          <li>Angebote erhalten: Sobald eine Anfrage abgeschickt ist, haben sich auf unserer Plattform registrierte
            Umzugsunternehmen die Möglichkeit, die Details zu überprüfen und wettbewerbsfähige Angebote abzugeben. Dies
            stellt
            sicher, dass Kunden mehrere Auswahlmöglichkeiten haben.</li>
          <li>Vergleichen und Auswählen: Kunden können dann die Angebote, Bewertungen und Bewertungen verschiedener
            Umzugsunternehmen vergleichen, um eine informierte Entscheidung zu treffen. Unsere Plattform bietet alle
            Informationen, die für die Auswahl des richtigen Umzugspartners benötigt werden.</li>
          <li>Buchung und Zahlung: Nach Auswahl eines Umzugsunternehmens können Kunden nahtlos deren Dienstleistungen
            direkt
            über unsere Plattform buchen. Wir erleichtern sichere Zahlungstransaktionen und bieten sowohl Kunden als
            auch
            Umzugsunternehmen Sicherheit.</li>
          <li>Bewertung und Feedback: Nach Abschluss des Umzugs können Kunden Feedback und Bewertungen basierend auf
            ihren
            Erfahrungen abgeben. Dieses Feedback hilft, die Qualität unserer Dienstleistung aufrechtzuerhalten und
            ermöglicht
            es anderen Kunden, informierte Entscheidungen zu treffen.</li>
        </ol>

        <h2>Warum Unsere Dienstleistung wählen?</h2>

        <ul>
          <li>Effizienz: Wir vereinfachen den gesamten Umzugsprozess, von der Angebotsanforderung bis zur Zahlung, um
            ihn
            effizienter und weniger zeitaufwändig zu gestalten.</li>
          <li>Transparenz: Unsere Plattform fördert die Transparenz, indem sie detaillierte Informationen über
            Umzugsunternehmen, einschliesslich ihrer Bewertungen und Bewertungen, bereitstellt. Kunden können fundierte
            Entscheidungen auf der Grundlage der Erfahrungen anderer treffen.</li>
          <li>Wettbewerbsfähige Preise: Durch die Möglichkeit für Umzugsunternehmen, auf Anfragen zu bieten, fördern wir
            den
            Wettbewerb und führen zu wettbewerbsfähigen Preisen für Kunden.</li>
          <li>Qualitätssicherung: Kundenbewertungen und -bewertungen gewährleisten, dass Umzugsunternehmen einen hohen
            Qualitätsstandard einhalten und die Zuverlässigkeit in der Branche verbessert wird.</li>
          <li>Zufriedenheitsgarantie: Wir sind bestrebt, die Zufriedenheit sowohl der Kunden als auch der
            Umzugsunternehmen
            sicherzustellen. Unser Support-Team steht bereit, um Fragen oder Bedenken zu klären.</li>
        </ul>
      </main>

      <div class="d-flex justify-content-center mt-4">
        <p>Build {{versions.hash}} von {{versions.date | date: 'yyyy-MM-dd HH:mm'}}</p>
      </div>

    </mat-card-content>

  </mat-card>
</div>


<div class="container">

  <div class="d-flex justify-content-center mt-4">
    <p class="mt-5">© 2024 Movline GmbH. All rights reserved</p>
  </div>

</div>
