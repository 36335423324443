import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { DashboardService } from 'projects/webapp/app/services/dashboard.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: "comments-card-component",
  templateUrl: './comments-card.component.html',
  styleUrls: ['./comments-card.component.scss']
})
export class CommentsCardComponent implements OnInit {

  @Input() title = '';

  @Input() adminMode = false;
  @Input() public = false;
  @Input() canAddNewComment = true;

  @Input() entityUUID: string | null = ''
  newComment = ''

  comments: any[] = [];

  constructor(
    private adminService: AdminService,
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.refreshComments();
  }

  async refreshComments() {
    try {
      if (this.entityUUID) {
        if (this.adminMode) {
          this.comments = await lastValueFrom(this.adminService.getComments(this.entityUUID, { public: this.public }))
        } else {
          this.comments = await lastValueFrom(this.dashboardService.getComments(this.entityUUID, { public: this.public }))
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('next-works')
    }

  }

  async onAddNewComment() {
    if (this.entityUUID) {
      if (this.adminMode) {
        await lastValueFrom(this.adminService.addComment(this.entityUUID, {
          content: this.newComment,
          public: this.public
        }));
      } else {
        await lastValueFrom(this.dashboardService.addComment(this.entityUUID, { content: this.newComment }));
      }
      this.newComment = '';
      this.refreshComments()
    }
  }

}
