import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class PublicOrderDetailsComponent implements OnInit {

  total_price = 0;

  isLoading = false;

  environment = environment;
  orderUUID: string | undefined = undefined;

  item: any | undefined = undefined
  showSuccessMessage = false;

  constructor(
    private spinnerService: NgxSpinnerService,
    private publicInquiryService: PublicInquiryService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!!params['tk']) {
        this.orderUUID = params['tk']
        this.refresh()
      }
    });

  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show('load-order')
      if (this.orderUUID) {
        this.item = await lastValueFrom(this.publicInquiryService.getOrder(this.orderUUID))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('load-order')
      this.isLoading = false;
    }
  }
}
