import { IOfferObject } from "../services/dashboard.service";


export enum InquiryStatus {
  UNKNOWN = 0,
  DRAFT = 10,
  OPEN = 20,
  ACCEPTED = 30,
  OPEN_FOR_BIDS = 40,
  WAITING_FOR_ACCEPTANCE = 50,
  ASSIGNED = 60,
  DONE = 70,
  ARCHIVED = 80,
  DELETED = 90,
}


export enum InquiryServiceType {
  MOVE_AND_CLEAN = "1",
  MOVE = "2",
  CLEAN = "3",
}

export interface IInquiry {
  email: any;

  to_address: any;
  to_zip: string;
  to_location: string;

  from_address: any;
  from_location: string;
  from_zip: string;

  status: InquiryStatus,
  moving_date: string | number | Date | null | undefined | any;
  cleaning_date: string | number | Date | null | undefined | any;
  handover_date: string | number | Date | null | undefined | any;
}


export interface IDetailedInquiry extends IInquiry {
  tenant: string;
  organization_object: any;
  id: number,
  uuid: string,
  created_at: Date,
  updated_at: Date,

  from_zip: string,
  from_location: string,
  from_address: string,
  to_zip: string,
  to_location: string,
  to_address: string,
  service: InquiryServiceType,

  email: string,
  phone: string;

  newsletter: boolean,

  gender: string,
  first_name: string,
  last_name: string,

  from_type: string,
  from_size_rooms: string | null | undefined;
  from_size_square_meters: string,
  from_size_floors: string | null | undefined;
  from_type_of_elevator: string,

  from_has_more_than_5_meters: boolean,
  from_way_from_parking_meters: string,

  from_has_stair_steps: boolean,
  from_number_of_stair_steps: string,

  from_other_difficulties: boolean,
  from_other_difficulties_description: string,

  to_type: string,
  to_size_rooms: string,
  to_size_square_meters: string,
  to_size_floors: string,
  to_type_of_elevator: string,

  to_has_more_than_5_meters: boolean,
  to_way_from_parking_meters: string,

  to_has_stair_steps: boolean,
  to_number_of_stair_steps: string,

  to_other_difficulties: boolean,
  to_other_difficulties_description: string,

  number_of_persons: string,
  number_of_cartons: string,
  service_trash_per_cubic: boolean,

  service_mount_lamps: boolean,
  service_number_of_lamps: number;

  service_floorliner: boolean,
  service_boxes: boolean,

  services_clothes_boxes: boolean,
  service_number_of_clothes_boxes: number;

  services_furniture_lift: boolean,
  services_assembling: boolean,
  inventar: {
    name: string,
    furniture: {
      name: string;
      number: number;
      details: string;
    }[]
  }[]

  offer_objects?: IOfferObject[]


  what_to_clean_all_rooms: boolean;
  what_to_clean_kitchen_toilet: boolean;
  what_to_clean_attic: boolean;
  what_to_clean_winter_garden: boolean;
  what_to_clean_balcony: boolean;
  what_to_clean_basement: boolean;
  special_clean_build_in_wardrobes: boolean;
  special_clean_hardened_dirt: boolean;
  special_clean_mold: boolean;
  special_clean_has_stuff: boolean;
  special_clean_has_washing_machine: boolean;
  special_clean_has_pets: boolean;
  special_clean_has_cheminee: boolean;
  special_clean_shampoo_carpet: boolean;
  special_clean_shampoo_carpet_number: string;
  number_of_showers: string;
  number_of_toilets: string;
  number_of_sinks: string;
  window_normal: string;
  window_wall: string;
  window_doors: string;

}

export interface IAdminDetailedInquiry extends IDetailedInquiry {
  offer_objects: any[];
}
