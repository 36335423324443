<ngx-spinner [name]="'choose-offer'" [fullScreen]="false">Loading...</ngx-spinner>

<section class="header">
  <div class="div-block-4"><img src="/assets/logos/movline-logo-white.png" loading="lazy" width="207" alt=""></div>
</section>
<div class="step-1" *ngIf="item">

  <div *ngIf="!showSuccessMessage" style="padding: 15px;">

    <div *ngIf="item.tenant else notenant">
      <h1 class="heading">Das Angebot der Movline-Partner Firma</h1>
      <p class="paragraph-9">Um die Offerte zu akzeptieren, wählen Sie bitte die Details mit den Boxen, anschliessend mit bitte unten bestätigen.</p>
    </div>

    <ng-template #notenant>
      <div>
        <h1 class="heading">Wählen Sie das beste Angebot aus</h1>
        <p class="paragraph-9">Um die Offerte auszuwählen, drücken Sie einfach auf die Box.</p>
      </div>
    </ng-template>

    <form [formGroup]="form">
      <div id="w-node-d6805926-e433-82cc-79e3-a3863a9b23a9-b71d2c8e"
        class="w-layout-layout quick-stack-3 wf-layout-layout">

        <div id="w-node-d6805926-e433-82cc-79e3-a3863a9b23aa-b71d2c8e" class="w-layout-cell"
          *ngFor="let offer of offers">
          <div style="width: 100%;">
          <div class="offerten-wrapper" (click)="selectThisOffer(offer)" style="cursor: pointer;"
            [ngClass]="{ 'selected': form.get('offer')?.value == offer.uuid }">
            <h3 class="firma">{{offer.organization_object.name}}</h3>

            <span style="display: flex; flex-direction: row; justify-content: space-between;"
              *ngIf="offer.price_move > 0 && (item.service == inquiryServiceType.MOVE || item.service == inquiryServiceType.MOVE_AND_CLEAN)">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_move">Umzugspreis:
              </span>
              <span>{{offer.price_move | thousandsSeparator}}</span>
            </span>

            <span style="display: flex; flex-direction: row; justify-content: space-between;" *ngIf="offer.price_cleaning >0 && (item.service == inquiryServiceType.CLEAN || item.service ==
              inquiryServiceType.MOVE_AND_CLEAN)">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_cleaning">Reinigung:
              </span>
              <span>{{offer.price_cleaning | thousandsSeparator}}</span>
            </span>

            <span style="display: flex; flex-direction: row; justify-content: space-between;"
              *ngIf="offer.price_montage >0 &&  item.services_assembling">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_montage">Ab- und Aufbau:
              </span>
              <span>{{offer.price_montage | thousandsSeparator}}</span>
            </span>

            <span style="display: flex; flex-direction: row; justify-content: space-between;"
              *ngIf="offer.price_disposal >0 &&  item.service_trash_per_cubic">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_disposal">Entsorgung pro m3:
              </span>
              <span>{{offer.price_disposal | thousandsSeparator}}</span>
            </span>

            <span style="display: flex; flex-direction: row; justify-content: space-between;"
              *ngIf="offer.price_service_mount_lamps >0 && item.service_mount_lamps">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_service_mount_lamps">Lampenmontage:
              </span>
              <span>{{offer.price_service_mount_lamps | thousandsSeparator}}</span>
            </span>

            <span style="display: flex; flex-direction: row; justify-content: space-between;"
              *ngIf="offer.price_service_floorliner >0 && item.service_floorliner">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_service_floorliner">Floorliner:
              </span>
              <span>{{offer.price_service_floorliner | thousandsSeparator}}</span>
            </span>

            <span style="display: flex; flex-direction: row; justify-content: space-between;"
              *ngIf="offer.price_service_boxes >0 &&  item.service_boxes">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_service_boxes">Kisten einpacken:
              </span>
              <span>{{offer.price_service_boxes | thousandsSeparator}}</span>
            </span>

            <span style="display: flex; flex-direction: row; justify-content: space-between;"
              *ngIf="offer.price_services_clothes_boxes >0  && item.services_clothes_boxes">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_services_clothes_boxes">Kleiderboxenmiete:
              </span>
              <span>{{offer.price_services_clothes_boxes | thousandsSeparator}}</span>
            </span>

            <span style="display: flex; flex-direction: row; justify-content: space-between;"
              *ngIf="offer.price_services_furniture_lift >0 && item.services_furniture_lift">
              <span>
                <input type="checkbox" [ngClass]="{ 'hidden': form.get('offer')?.value != offer.uuid }"
                  formControlName="price_services_furniture_lift">Möbellift:
              </span>
              <span>{{offer.price_services_furniture_lift | thousandsSeparator}}</span>
            </span>

            <p [ngClass]="{ 'price': true, 'hidden': form.get('offer')?.value != offer.uuid }"
              style="font-weight: 900;">Total: {{(total_price) | thousandsSeparator}} CHF</p>
          </div>
          <input style="display: none;" id="{{offer.uuid}}" type="radio" formControlName="offer" value="{{offer.uuid}}">
          </div>
          <div style="width: 100%;">
            <a href="/company-rating?tk={{offer.organization_object.uuid}}" target="_blank">Bewertungen</a>
          </div>
        </div>

      </div>
      <br>
      <br>

      <br>
      <p class="mt-3 mb-3 alert alert-info" style="font-weight: bold; ">
        Falls sie eine Besichtigung wünschen, so schreiben sie uns eine E-Mail an <a href="mailto:support@movline.ch">info@movline24.ch</a> oder rufen Sie uns an unter <a href="tel:0448368888">044 836 88 88</a>
      </p>
      <br>
      <br>
      <br>
      <br><br>
      <div style="display: flex; justify-content: space-around;">
        <div class="payment-method-wrapper" style="cursor: pointer;" (click)="selectThisPaymentMethod('invoice')"
          [ngClass]="{ 'selected': form.get('payment_method')?.value =='invoice' }">
          <h4>Rechnung</h4>
          <img src="/assets/invoice.jpeg">
          <input style="display: none;" type="radio" formControlName="payment_method" value="invoice">
        </div>
        <div class="payment-method-wrapper" style="cursor: pointer;"
          (click)="selectThisPaymentMethod('cash_at_location')"
          [ngClass]="{ 'selected': form.get('payment_method')?.value =='cash_at_location' }">
          <h4>Bar</h4>
          <img src="/assets/cash.jpeg">
          <input style="display: none;" type="radio" formControlName="payment_method" value="cash_at_location">
        </div>
      </div>

      <br>
      <div style="display: flex; justify-content: space-around;" class="mb-5">
        <label class="w-checkbox">
          <input type="checkbox" id="checkbox-3" name="checkbox-3" formControlName="terms_accepted"
            data-name="Checkbox 3" class="w-checkbox-input">
          <span class="w-form-label" for="checkbox-3">
            Ich akzeptiere die <a href="https://www.movline24.ch/agbs">AGBs</a>
          </span>
        </label>
      </div>

      <input type="submit" (click)="onSubmit()" data-wait="Please wait..." class="submit-button w-button"
        value="Angebot annehmen">

    </form>

  </div>

  <div *ngIf="showSuccessMessage">
    <div style="width: 100%;" class="alert alert-success">Der Auftrag wurde übermittelt, Sie hören bald von uns.</div>

    <a style="width: 100%;" href="https://movline24.ch/" class="submit-button w-button mt-2">Zur Home Page</a>

    <a *ngIf="resultOrder" style="width: 100%;" href="{{environment.uiDomain}}/order-details?tk={{resultOrder.uuid}}"
      class="submit-button w-button mt-2">Auftrag sehen</a>
  </div>

</div>

<pre *ngIf="environment.envName == 'local_dev'">{{form.value | json}}</pre>
<pre *ngIf="environment.envName == 'local_dev'">{{item | json}}</pre>
<pre *ngIf="environment.envName == 'local_dev'">{{offers | json}}</pre>
