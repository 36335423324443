import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    if (!this.authService.isAuthenticatedUser()) {
      console.log('not authenticated');
      return this.router.parseUrl('/login');
    }

    if (route.data['needAdminAccess'] && !this.authService.isAuthenticatedPlatformAdmin())
      return this.router.parseUrl('/');

    return true;
  }
}
