import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'yesNoPipe' })
export class YesNoPipe implements PipeTransform {
  constructor() { }

  transform(status: boolean): string {
    if (status) {
      return 'Ja'
    }
    return 'Nein'
  }
}


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'genderPipe' })
export class GenderPipe implements PipeTransform {
  constructor() { }

  transform(gender: string): string {
    switch(gender) {
      case 'man':
        return 'Herr'
      case 'woman':
        return 'Frau'
      default:
        return '-'
    }
  }
}
