<body class="body">
  <app-inquiry-header [step]="1"></app-inquiry-header>
  <div class="step-1">
    <h1 class="heading">Anfrage starten</h1>
    <p class="paragraph">Finden Sie die besten Angebote von Firmen aus Ihrer Region</p>
    <div class="step-1-wrapper">
      <div class="w-form">

        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form"
          data-wf-page-id="65a4fc14edc0989cff93a23e" data-wf-element-id="3c22ea9e-8587-6cdb-19ca-53e38eea0fb5"
          [formGroup]="draftInquiryForm">
          <div class="div-block-3"><label for="Service-w-hlen" class="field-label box edit">Service
              wählen</label>
            <select id="Service-w-hlen" name="Service-w-hlen" data-name="Service wählen" class="select-field w-select"
              formControlName="service">
              <option value="1">Umzug und Reinigung</option>
              <option value="2">Nur Umzug</option>
              <option value="3">Nur Reinigung</option>
            </select>

            <div *ngIf="draftInquiryForm.value.service == inquiryServiceType.CLEAN">
              <div>
                <label for="field-2" class="field-label">Objekt ist in</label>
                <div class="div-block">
                  <input class="text-field w-input" maxlength="256" name="PLZ" data-name="PLZ" placeholder="PLZ"
                    type="text" id="PLZ" formControlName="from_zip">
                  <input class="text-field-2 w-input" maxlength="256" name="Ortschaft" data-name="Ortschaft"
                    placeholder="Stadt" type="text" id="Ortschaft" formControlName="from_location">
                </div>
              </div>
            </div>

            <div *ngIf="draftInquiryForm.value.service != inquiryServiceType.CLEAN">
              <label for="email" class="service-heading box">Erfassen Sie Ihren Umzug</label>
              <div><label for="field-2" class="field-label">Umzug von</label>
                <div class="div-block">
                  <input class="text-field w-input" maxlength="256" name="PLZ" data-name="PLZ" placeholder="PLZ"
                    type="text" id="PLZ" formControlName="from_zip">
                  <input class="text-field-2 w-input" maxlength="256" name="Ortschaft" data-name="Ortschaft"
                    placeholder="Stadt" type="text" id="Ortschaft" formControlName="from_location">
                </div>
              </div>

              <div class="div-block-2"><label for="field-2" class="field-label">Umzug nach</label>
                <div class="div-block">
                  <input class="text-field w-input" maxlength="256" name="PLZ" data-name="PLZ" placeholder="PLZ"
                    type="text" id="PLZ-2" formControlName="to_zip">
                  <input class="text-field-2 w-input" maxlength="256" name="email-2" placeholder="Stadt" type="text"
                    id="email-2" formControlName="to_location">
                </div>

              </div>
            </div>

            <div>

              <input class="text-field-3 w-input" maxlength="256" name="field-2" data-name="Field 2"
                placeholder="E-Mail Adresse" type="email" id="field-2" formControlName="email">

              <input class="text-field-3 w-input" name="field-2" data-name="Field 2" placeholder="Telefon" type="phone"
                id="field-2" formControlName="phone">

              <p class="paragraph-2">
                Wir werden Sie Mithilfe der Kontaktangaben über Ihre Offerten Benachrichtigen.
                <a href="https://www.movline24.ch/datenschutz" target="_blank" class="link"><span
                    class="text-span">Datenschutzbestimmung</span>
                </a>
              </p>
            </div>

          </div>
          <input type="submit" (click)="onSubmit()" data-wait="Please wait..." class="submit-button w-button"
            [value]="submitText">
        </form>

        <pre *ngIf="environment.envName == 'local_dev'">{{draftInquiryForm.value | json}}</pre>

      </div>
    </div>
  </div>

<div class="d-flex justify-content-center mt-4">
  <p>Build {{versions.hash}} von {{versions.date | date: 'yyyy-MM-dd HH:mm'}}</p>
</div>

</body>
