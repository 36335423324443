import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { versions } from 'projects/versions';
import { AuthService } from 'projects/webapp/app/services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  versions = versions;
  isLoading = false;

  failedLogin = false;

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private title: Title,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
  ) {
    this.title.setTitle('Movline24: Login')
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/main'])
    }
  }

  loading(state: boolean) {
    this.isLoading = state;
    if (state) {
      this.spinner.show()
    } else {
      this.spinner.hide()
    }
  }

  onSubmit() {
    this.loading(true);
    this.failedLogin = false;
    this.authService.loginUser(this.form.value.username, this.form.value.password).subscribe((res) => {
      this.loading(false);
      if (res.success) {
        console.log('success -> moving to dashboard')
        this.router.navigate([`/main`]);
      } else {
        // this.user.username = '';
        // this.user.password = '';
        console.log(res)
        this.failedLogin = true;
        this.form.patchValue({ password: '' });
        if (res.reason['detail'] == 'no new logins allowed at this point in time') {
          this.toastrService.warning('Login momentan nicht möglich.')
        } else {
          this.toastrService.warning('Passwort und / oder Username nicht korrekt')
        }
      }
    });
  }
}
