import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InquiryServiceType } from '../../interfaces/inquiry.interface';


@Component({
  selector: 'app-bid-card-modal',
  templateUrl: './bid-card-modal.component.html',
  styleUrls: ['./bid-card-modal.component.scss']
})
export class BidCardModalComponent implements OnInit {
  form: FormGroup = new FormGroup({
    price_move: new FormControl(0, Validators.required),
    price_cleaning: new FormControl(0, Validators.required),

    price_montage: new FormControl(0, Validators.required),
    price_disposal: new FormControl(0, Validators.required),
    price_service_mount_lamps: new FormControl(0, Validators.required),
    price_service_floorliner: new FormControl(0, Validators.required),
    price_service_boxes: new FormControl(0, Validators.required),
    price_services_clothes_boxes: new FormControl(0, Validators.required),
    price_services_furniture_lift: new FormControl(0, Validators.required),
  });

  inquiryServiceType = InquiryServiceType

  constructor(
    public dialogRef: MatDialogRef<BidCardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      service: InquiryServiceType
      service_trash_per_cubic: boolean
      service_mount_lamps: boolean
      service_floorliner: boolean
      service_boxes: boolean
      services_clothes_boxes: boolean
      services_furniture_lift: boolean
      services_assembling: boolean
    },
  ) { }

  async ngOnInit() {
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }
    this.dialogRef.close(this.form.value)
  }


}
