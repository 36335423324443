<div class="div">
  <div class="div-2">
    <div class="div-3">
      <div class="div-4">Bewertungen</div>
    </div>
  </div>

  <div *ngIf="!isLoading && items.length == 0 ">
    <div class="alert alert-info mt-5" >Keine Bewertungen</div>
    <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
  </div>

    <!-- <pre *ngIf="environment.envName == 'local_dev'">
   {{items | json}}</pre> -->

  <div *ngFor="let item of items">
    <hr>
    <img loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b252affa75b65c46078e94c2c2f3459c99405da961cdb4182c5f47db6d64bc20?"
      class="img-3" />
    <div class="div-6">{{item.author}}</div>
    <div class="div-7">
      {{item.text}}
    </div>
    <img loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1b65bc4085f6923653af8dc3ad86f2b9f2977019b3a5a8dddd95b713f89846be?"
      class="img-4" />
    <div class="div-8">
      <div class="div-9">
        <span style="color: rgba(154, 153, 155, 1)">Datum: {{item.updated_at | date: 'yyyy-MM-dd'}}</span>
        <br />
        <span style="color: rgba(20, 97, 97, 1)">Auftragsnummer: {{item.order }}</span>
      </div>
      <div class="div-10">
        <div class="div-11">{{item.rating}}/5</div>
      </div>
    </div>
  </div>
</div>
