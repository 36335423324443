import { FormGroup } from "@angular/forms";

export const cleanObject = (obj: any) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj
}

export const deepCopy = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
}


export const outputErrors = (form: FormGroup) => {
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    if (control && control.invalid) {
      let message = '';
      if (control && control.errors && control.errors['required']) {
        message += `${field} is required. `;
      }
      console.log(message);
    }
  });
}


export const replaceURLs = (message: string) => {
    if (!message) { return; }

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, (url) => {
        let hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
        }
        return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    });
};


export const getUniqueObjects = <T>(array: T[]): T[] => {
  const uniqueObjects = new Set();
  const uniqueArray: T[]= [];

  array.forEach(obj => {
    const objString = JSON.stringify(obj);

    if (!uniqueObjects.has(objString)) {
      uniqueObjects.add(objString); // track unique stringified objects
      uniqueArray.push(obj); // add the original object to the array
    }
  });

  return uniqueArray;
}


export const downloadFile = (blob: Blob, filename: string) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const parseFilename = (contentDisposition: string): string | null => {
    if (!contentDisposition) { return null; }
    const fileNameRaw = contentDisposition.split('filename=')[1];
    return fileNameRaw.replace(/"/g, '');
}

export const openInGoogleMaps = (address: string, zip_code: string | number, location: string) => {
    const url = 'http://maps.google.com/?q=' + `${address}, ${zip_code} ${location}`;
    window.open(url);
}


export const sleep = (m: number) => new Promise(r => setTimeout(r, m))
