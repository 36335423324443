import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.scss']
})
export class AdminOrdersListComponent implements OnInit {

  public isLoading = true;
  items: any[] = []

  constructor(
    private title: Title,
    private router: Router,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Admin Orders`);
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      this.items = await lastValueFrom(this.adminService.getOrders())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  onClickDetails(uuid: string) {
    this.router.navigate(['/main/admin/orders/' + uuid])
  }

}
