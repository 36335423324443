<div class="container mt-4 mb-4">
  <div style="display: flex; justify-content: space-between;">
    <h2>Übersicht</h2>
    <div>
      <div class="spinner-grow" role="status" *ngIf="isLoading">
      </div>
      <!-- <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a> -->
    </div>
  </div>
</div>

<div class="container">
  <div class="row">


    <div class="col-xl-4">
      <div class="mb-4">
        <mat-card>
          <mat-card-content>
            <app-statistics-card></app-statistics-card>
          </mat-card-content>
        </mat-card>
      </div>
    </div>


    <div class="col-xl-4">
      <div class="mb-4">
        <mat-card>
          <mat-card-content>
            <app-next-works-card></app-next-works-card>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-xl-4">
      <div class="mb-4">
        <mat-card>
          <mat-card-content>
            <app-rating-card></app-rating-card>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

</div>
