import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from './url-constants.service';
import { IDetailedInquiry, InquiryServiceType } from '../interfaces/inquiry.interface';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PublicInquiryService {

  public inquiryServiceType$: BehaviorSubject<InquiryServiceType> = new BehaviorSubject<InquiryServiceType>(InquiryServiceType.MOVE_AND_CLEAN);

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  getMyRatings(companyUUID: string) {
    return this.http.get<any>(this._urls.PUBLIC_RATING, { params: { companyUUID }})
  }

  createEnquiry(formValue: any) {
    return this.http.post<any>(this._urls.PUBLIC_INQUIRIES, formValue)
  }

  updateEnquiry(
    uuid: string,
    formValue: any
      // Partial<IDetailedInquiry>
    ) {
    return this.http.patch<any>(this._urls.PUBLIC_INQUIRIES_BY_UUID(uuid), formValue)
  }

  getEnquiry(uuid: string): Observable<IDetailedInquiry> {
    return this.http.get<any>(this._urls.PUBLIC_INQUIRIES_BY_UUID(uuid))
  }

  getOrder(uuid: string): Observable<any> {
    return this.http.get<any>(this._urls.PUBLIC_ORDERS_BY_UUID(uuid))
  }

  getOffer(inquiryUUID: string) {
    return this.http.get<any>(this._urls.PUBLIC_INQUIRY_OFFERS_BY_UUID(inquiryUUID))
  }

  chooseOffer(inquiryUUID: string, formValue: any) {
    return this.http.post<any>(this._urls.PUBLIC_INQUIRY_OFFERS_BY_UUID(inquiryUUID), formValue)
  }

  createRating(formValue: any) {
    return this.http.post<any>(this._urls.PUBLIC_RATING, formValue)
  }

}
