import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { outputErrors } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';


export const generateFurnitureFormGroup = () => {
  return new FormGroup({
    name: new FormControl('', Validators.required),
    number: new FormControl(1, Validators.required),
    details: new FormControl(''),
  });
}

export const generateRoomFormGroup = () => {
  return new FormGroup({
    furniture: new FormArray([generateFurnitureFormGroup()]),
    name: new FormControl('', Validators.required),
  });
}


@Component({
  selector: 'app-inquiry-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss']
})
export class InquiryStep5Component implements OnInit {

  public draftInquiryForm = new FormGroup({
    inventar: new FormArray([generateRoomFormGroup()]),
    service: new FormControl('1', Validators.required),
  });

  public roomItemForms: FormArray = this.draftInquiryForm.get('inventar') as FormArray;

  currentUUID: string = ''
  environment = environment;

  constructor(
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private publicInquiryService: PublicInquiryService) {
  }

  async ngOnInit() {

    this.draftInquiryForm.get('service')?.valueChanges.subscribe((value) => {
      const service = this.draftInquiryForm.get('service');
      if (service && service.value) {
        this.publicInquiryService.inquiryServiceType$.next(service.value as InquiryServiceType)
      }
    });

    const currentUUID = localStorage.getItem('currentInquiryUUID');
    if (currentUUID) {
      this.currentUUID = currentUUID
      const response = await lastValueFrom(this.publicInquiryService.getEnquiry(this.currentUUID));
      console.log(response)
      this.draftInquiryForm.patchValue(response);
    } else {
      this.router.navigate(['/inquiry/1'])
    }
  }

  async onSubmit() {
    this.draftInquiryForm.markAllAsTouched()
    if (!this.draftInquiryForm.valid) {
      console.log(this.draftInquiryForm.errors)
      outputErrors(this.draftInquiryForm);
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    try {
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.updateEnquiry(this.currentUUID, this.draftInquiryForm.value))
      this.router.navigate(['/inquiry/6'])
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.spinnerService.hide()
    }
  }

  onAddNewRoom() {
    const roomItemsCount = this.roomItemForms.controls.length;
    const roomItemForm = generateRoomFormGroup();
    this.roomItemForms.insert(roomItemsCount, roomItemForm);
  }

  removeRoomItem($event: number) {
    if (this.roomItemForms){
      this.roomItemForms.removeAt($event);
    }
  }

}
