import { Component, OnInit, Optional } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SwUpdate } from '@angular/service-worker';
import { versions } from 'projects/versions';
import { environment } from './environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  usingAsInstalledApp = false;

  constructor(
    @Optional() private angularFireAnalytics: AngularFireAnalytics,
    private updates: SwUpdate,
  ) {
  }

  public ngOnInit(): void {
    if (this.checkIfInstalledWebApp()) {
      this.angularFireAnalytics?.logEvent(
        `using_installed_web_app`, {
        app_name: 'webapp',
        app_version: versions.date
      }
      );
    }

    if (environment.envName !== 'local_dev') {
      this.handleUpdates()
    }
  }

  private handleUpdates() {
    const location = window.location.pathname
    console.log(`location: ${location}`)

    if (this.updates.isEnabled) {
      console.log('this.updates.isEnabled')
      this.updates.checkForUpdate().then(val => {
        console.log(`checkForUpdate: ${val}`)
      }).catch((error) => {
        console.error(error)
      });
    }
    this.updates.activateUpdate().then((event) => {
      console.log('activated', event);
    });
    this.updates.unrecoverable.subscribe((event) => {
      console.error('unrecoverable', event);
      console.error(event.reason);
    });
    this.updates.versionUpdates.subscribe((event) => {
      console.log('available', event);
      console.log('old version was', event);
      console.log('new version is', event);
      if (event.type == 'VERSION_READY') {
        this.angularFireAnalytics?.logEvent(`update_available`);
        if (!location.includes('/main')) {
          document.location.reload();
        } else {
          if (confirm('Es gibt eine neue Version der App, jetzt updaten?') === true) {
            this.updates.activateUpdate().then(() => {
              this.angularFireAnalytics?.logEvent(`performing_update`);
              // document.location.reload();
            });
          }
        }
      }

      if (event.type == 'VERSION_INSTALLATION_FAILED') {
        console.error(event)
      }
    });
  }

  private checkIfInstalledWebApp() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.usingAsInstalledApp = true;
      return true;
    }
    this.usingAsInstalledApp = false;
    return false;
  }


}
