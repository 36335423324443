<section class="section wf-section">
    <div class="div-block">
        <div class="kachel">
            <h1 class="heading-3">Konto erstellen</h1>
            <div class="form-block w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form"
                    data-wf-page-id="64b5a624e94bf56c8bfae507" data-wf-element-id="86e0f79c-eceb-62bd-ed48-fa589d6b90a6"
                    [formGroup]="form" style="padding-top: 15px;">


                    <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>Firmenname</mat-label>
                        <input type="text" matInput placeholder="Firmenname" formControlName="company_name">
                        <mat-error class="text-danger" *ngIf="form.get('company_name')?.hasError('required')">
                            Dieses Feld ist erforderlich.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" appearance="outline">
                        <mat-label>Vorname</mat-label>
                        <input type="text" matInput placeholder="Vorname" formControlName="first_name">
                        <mat-error class="text-danger" *ngIf="form.get('first_name')?.hasError('required')">
                            Dieses Feld ist erforderlich.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" appearance="outline">
                        <mat-label>Nachname</mat-label>
                        <input type="text" matInput placeholder="Nachname" formControlName="last_name">
                        <mat-error class="text-danger" *ngIf="form.get('last_name')?.hasError('required')">
                            Dieses Feld ist erforderlich.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" style="width: 100%" appearance="outline">
                        <mat-label>E-Mail</mat-label>
                        <input type="email" matInput placeholder="E-Mail" formControlName="email">
                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('required')">
                            Dieses Feld ist erforderlich.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('pattern')">
                            Eingabe ist keine gültige E-Mail
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('emailTaken')">
                            Diese Email existiert bereits, bitte anmelden.
                        </mat-error>

                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('userBlocked')">
                            Benutzer is blockiert.
                        </mat-error>

                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('userBlocked')">
                            Organization is blockiert.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" style="width: 100%" appearance="outline">
                        <mat-label>Telefonnummer</mat-label>
                        <input type="tel" matInput placeholder="Telefonnummer" formControlName="phone">
                        <mat-error class="text-danger" *ngIf="form.get('phone')?.hasError('required')">
                            Dieses Feld ist erforderlich.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('phone')?.hasError('pattern')">
                            Invalid phone number.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" style="width: 100%" appearance="outline">
                      <mat-label>
                        Website
                      </mat-label>
                      <input type="text" matInput placeholder="Website" formControlName="website">
                      <mat-error class="text-danger" *ngIf="form.get('website')?.hasError('required')">
                        Dieses Feld ist erforderlich.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" style="width: 100%" appearance="outline">
                        <mat-label>Passwort</mat-label>
                        <input type="password" matInput placeholder="Passwort" formControlName="password">
                        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('required')">
                            Dieses Feld ist erforderlich.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('minlength')">
                            <span>Die Mindestlänge für dieses Feld beträgt
                            </span>{{form.get('password')?.errors?.["minlength"]?.requiredLength}}.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('maxlength')">
                            <span>Die maximale Länge für dieses Feld beträgt
                            </span>{{form.get('password')?.errors?.["maxlength"]?.requiredLength}}.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('pattern')">
                            Das Passwort muss Kleinbuchstaben (a-z) und Grossbuchstaben (A-Z) sowie Zahlen enthalten (0-9).
                        </mat-error>

                    </mat-form-field>

                    <hr>
                    <mat-checkbox formControlName="accept_terms">
                        Ich habe die <a href="https://www.movline24.ch/agbs" target="_blank">AGBs</a> gelesen und
                        bin damit einverstanden.
                    </mat-checkbox>

                    <mat-error class="text-danger"
                        *ngIf="form.get('accept_terms')?.touched && form.get('accept_terms')?.hasError('required')">
                        Sie müssen die Movline24 AGBs akzeptieren, damit Sie die Platform nutzen können.
                    </mat-error>

                    <p class="paragraph-2">Sie haben bereits einen Account: <a routerLink="/login" class="link-4">jetzt
                            anmelden</a>

                        <button (click)="onSubmit()" class="button handy-20top w-button w-100"
                            [disabled]="isLoading">Registrieren</button>
                </form>
            </div>
        </div>
    </div>
</section>
