import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { IDetailedInquiry, InquiryServiceType, InquiryStatus } from 'projects/webapp/app/interfaces/inquiry.interface';
import { IDetailedOffer } from 'projects/webapp/app/interfaces/offer.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { outputErrors } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-choose-offer',
  templateUrl: './choose-offer.component.html',
  styleUrls: ['./choose-offer.component.scss']
})
export class ChooseOfferComponent implements OnInit {

  public form = new FormGroup({
    token: new FormControl('', Validators.required),
    offer: new FormControl('', Validators.required),
    terms_accepted: new FormControl(false, Validators.requiredTrue),
    payment_method: new FormControl('invoice', Validators.required),

    price_move: new FormControl(true),
    price_montage: new FormControl(true),
    price_cleaning: new FormControl(true),
    price_disposal: new FormControl(true),
    price_service_mount_lamps: new FormControl(true),
    price_service_floorliner: new FormControl(true),
    price_service_boxes: new FormControl(true),
    price_services_clothes_boxes: new FormControl(true),
    price_services_furniture_lift: new FormControl(true),

  });

  total_price = 0;

  isLoading = false;

  environment = environment;
  inquiryUUID: string | undefined = undefined;

  offers: IDetailedOffer[] = []
  item: IDetailedInquiry | undefined = undefined
  showSuccessMessage = false;
  resultOrder: any = null;
  inquiryServiceType = InquiryServiceType;

  constructor(
    private spinnerService: NgxSpinnerService,
    private publicInquiryService: PublicInquiryService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!!params['tk']) {
        this.form.patchValue({ token: params['tk'] });
        this.inquiryUUID = params['tk']
        this.refresh()
      }
    });

    this.form.valueChanges.subscribe((value) => {
      console.log(`value changed:`);
      console.log(value);

      this.total_price = 0

      this.offers.forEach(offer =>{
        if (offer.uuid == value.offer) {

          if (value.price_move) {
            this.total_price += parseInt(offer.price_move)
          }
          if (value.price_montage) {
            this.total_price += parseInt(offer.price_montage)
          }
          if (value.price_cleaning) {
            this.total_price += parseInt(offer.price_cleaning)
          }
          if (value.price_disposal) {
            this.total_price += parseInt(offer.price_disposal)
          }
          if (value.price_service_mount_lamps) {
            this.total_price += parseInt(offer.price_service_mount_lamps)
          }
          if (value.price_service_floorliner) {
            this.total_price += parseInt(offer.price_service_floorliner)
          }
          if (value.price_service_boxes) {
            this.total_price += parseInt(offer.price_service_boxes)
          }
          if (value.price_services_clothes_boxes) {
            this.total_price += parseInt(offer.price_services_clothes_boxes)
          }
          if (value.price_services_furniture_lift) {
            this.total_price += parseInt(offer.price_services_furniture_lift)
          }
          console.log(this.total_price)
        }
      });
    });
  }

  async refresh() {
    try {
      this.spinnerService.show('choose-offer')
      this.isLoading = true;
      if (this.inquiryUUID) {
        this.item = await lastValueFrom(this.publicInquiryService.getEnquiry(this.inquiryUUID))
        this.offers = await lastValueFrom(this.publicInquiryService.getOffer(this.inquiryUUID))
        this.checkIfAlreadyDone()
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('choose-offer')
      this.isLoading = false;
    }
  }

  checkIfAlreadyDone() {
    if (!!this.item && this.item.status == InquiryStatus.ASSIGNED) {
      this.showSuccessMessage = true;
    }
  }

  async onSubmit() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      console.log(this.form.errors)
      outputErrors(this.form)
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show('choose-offer')
      if (this.inquiryUUID) {
        this.resultOrder = await lastValueFrom(this.publicInquiryService.chooseOffer(this.inquiryUUID, this.form.value));
        this.toastrService.success('Der Auftrag wurde übermittelt, Sie hören bald von uns.')
        this.showSuccessMessage = true;
      }
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.spinnerService.hide('choose-offer')
      this.isLoading = false;
    }
  }

  selectThisOffer(offer: any) {
    // let did_change_offer = false;
    if (offer.uuid != this.form.value.offer){
      // did_change_offer = true;
      this.form.patchValue({ price_move: true })
      this.form.patchValue({ price_montage: true })
      this.form.patchValue({ price_cleaning: true })
      this.form.patchValue({ price_disposal: true })
      this.form.patchValue({ price_service_mount_lamps: true })
      this.form.patchValue({ price_service_floorliner: true })
      this.form.patchValue({ price_service_boxes: true })
      this.form.patchValue({ price_services_clothes_boxes: true })
      this.form.patchValue({ price_services_furniture_lift: true })
    }

    this.form.patchValue({ offer: offer.uuid });

  }

  selectThisPaymentMethod(method: any) {
    this.form.patchValue({ payment_method: method })
  }

}
