<body class="body">
  <app-inquiry-header [step]="5"></app-inquiry-header>
  <div class="step-1">
    <h1 class="heading">Inventar</h1>
    <p class="paragraph">Erfassen Sie Ihre Möbelstücke</p>
    <p class="paragraph-9">Bitte erfassen Sie alle Möbelstücke, welche nicht in Kartons gepackt werden. Wir empfehlen
      Ihnen in einem Zimmer zu starten und nachdem Sie alle Möbel erfasst haben ins nächste Zimmer zu gehen. Bitte
      denken
      Sie daran alle Möbel zu erfassen, auch die im Keller und Estrich usw. Falls nicht alle Möbel erfasst werden,
      können
      wir den Transport nicht korrekt planen und durchführen.</p>
    <div class="step-1-wrapper">
      <div class="w-form">

        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form"
          [formGroup]="draftInquiryForm" data-wf-page-id="65ccf2699b4f869a6cf40f2d"
          data-wf-element-id="3c22ea9e-8587-6cdb-19ca-53e38eea0fb5">

          <div class="main-wrapper">

            <ng-container *ngFor="let roomForm of roomItemForms.controls; index as index">
              <div class="mb-5">
                  <app-room
                  [index]="index"
                  [roomForm]="roomForm"
                  [number_of_rooms]="roomItemForms.controls.length"
                  (deleteRoom)="removeRoomItem($event)"
                  >
                  </app-room>
              </div>
            </ng-container>

            <div class="div-block-8">
              <button (click)="onAddNewRoom()" class="button blue w-button" *ngIf="roomItemForms.controls.length == 0">erstes Zimmer hinzufügen</button>
              <button (click)="onAddNewRoom()" class="button blue w-button" *ngIf="roomItemForms.controls.length >= 1">weiteres Zimmer hinzufügen</button>
            </div>

          </div>
          <input (click)="onSubmit()" type="submit" data-wait="bitte warten" class="submit-button w-button"
            value="WEITER" style="margin-bottom: 20px;">
          <a class="movline-back-button w-button" [routerLink]="['..']" style="text-align: center;">ZURÜCK</a>
        </form>
        <pre *ngIf="environment.envName == 'local_dev'">{{draftInquiryForm.value | json}}</pre>

      </div>
    </div>
  </div>

</body>
