<body class="body">
  <app-inquiry-header [step]="2"></app-inquiry-header>
  <div class="step-1">
    <h1 class="heading">Jetziges Zuhause</h1>
    <p class="paragraph">Wo ziehen Sie aus?</p>
    <div class="step-1-wrapper">
      <div class="w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form"
          data-wf-page-id="65cbd3adbc09d304d41280b8" data-wf-element-id="3c22ea9e-8587-6cdb-19ca-53e38eea0fb5"
          [formGroup]="draftInquiryForm">
          <div class="main-wrapper">
            <label for="Service-w-hlen" class="field-label box edit">Wo ziehen Sie aus?</label>

            <app-button-selector [control]="$any(draftInquiryForm).controls['from_type']"></app-button-selector>

            <div class="haus">
              <label for="Service-w-hlen-2" class="field-label box edit _20pxtop">Wie gross ist ihr
                Objekt?</label>

              <app-selector name="from_size_rooms" [control]="$any(draftInquiryForm).controls['from_size_rooms']"
                [fieldName]="selectors.number_of_rooms.fieldName"
                [optionsList]="selectors.number_of_rooms.options"></app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['from_size_square_meters']"
                [fieldName]="selectors.from_size_square_meters.fieldName"
                [optionsList]="selectors.from_size_square_meters.options"></app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['from_size_floors']"
                [fieldName]="selectors.from_size_floors.fieldName"
                [optionsList]="selectors.from_size_floors.options"></app-selector>

            </div>
            <div class="lift">
              <label for="Service-w-hlen-2" class="field-label box edit _20pxtop">Ist ein Lift
                vorhanden?</label>
              <div class="lift-checkbox-1">
                <label class="w-checkbox ms-select-button-2" [ngClass]="{ 'selected': draftInquiryForm.get('from_type_of_elevator')?.value == '0' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div>
                  <input id="checkbox-8" type="radio" formControlName="from_type_of_elevator" value="0"
                    style="opacity:0;position:absolute;z-index:-1">
                    <span class="ms-dropdown-cb-label-2 w-form-label"
                    for="checkbox-8">Nein</span>
                </label>
                <label class="w-checkbox ms-select-button-2" [ngClass]="{ 'selected': draftInquiryForm.get('from_type_of_elevator')?.value == '1' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div>
                  <input id="checkbox-8" type="radio" formControlName="from_type_of_elevator" value="1"
                    style="opacity:0;position:absolute;z-index:-1"><span class="ms-dropdown-cb-label-2 w-form-label"
                    for="checkbox-8" >Ja, für 2-3 Personen</span>
                </label>
              </div>
              <div class="lift-checkbox-3">
                <label class="w-checkbox ms-select-button-2" [ngClass]="{ 'selected': draftInquiryForm.get('from_type_of_elevator')?.value == '2' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div>
                  <input id="checkbox-8" type="radio" formControlName="from_type_of_elevator" value="2"
                    style="opacity:0;position:absolute;z-index:-1"><span class="ms-dropdown-cb-label-2 w-form-label"
                    for="checkbox-8">Ja, fur 4-5 Personen</span>
                </label><label class="w-checkbox ms-select-button-2" [ngClass]="{ 'selected': draftInquiryForm.get('from_type_of_elevator')?.value == '3' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div>
                  <input id="checkbox-8" type="radio" formControlName="from_type_of_elevator" value="3"
                    style="opacity:0;position:absolute;z-index:-1"><span class="ms-dropdown-cb-label-2 w-form-label"
                    for="checkbox-8">Ja, für 6+ Personen</span>
                </label>
              </div>
              <div class="lift-checkbox-3"><label class="w-checkbox ms-select-button-2 _100">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb" [ngClass]="{ 'selected': draftInquiryForm.get('from_type_of_elevator')?.value == '4' }"></div>
                  <input id="checkbox-8" type="radio" formControlName="from_type_of_elevator" value="4"
                    style="opacity:0;position:absolute;z-index:-1"><span class="ms-dropdown-cb-label-2 w-form-label"
                    for="checkbox-8">Ja, Warenlift für 10+ Personen</span>
                </label>
              </div>
            </div>
            <div class="parkplatz"><label for="Service-w-hlen-2" class="field-label box edit _20pxtop">Weg vom Parkplatz
                zum Gebäude</label><label class="w-checkbox checkbox-field">

                <input type="checkbox" id="checkbox-9" formControlName="from_has_more_than_5_meters" name="checkbox-9"
                  data-name="Checkbox 9" class="w-checkbox-input checkbox">
                <span class="checkbox-label w-form-label" for="checkbox-9">... ist langer als 5 Meter</span>
              </label>

              <app-selector [control]="$any(draftInquiryForm).controls['from_way_from_parking_meters']"
                [fieldName]="selectors.from_way_from_parking_meters.fieldName"
                [optionsList]="selectors.from_way_from_parking_meters.options"></app-selector>

              <label class="w-checkbox checkbox-field">
                <input type="checkbox" id="checkbox-9" name="checkbox-9" formControlName="from_has_stair_steps"
                  data-name="Checkbox 9" class="w-checkbox-input checkbox"><span class="checkbox-label w-form-label"
                  for="checkbox-9">... hat Stufen</span>
              </label>

              <app-selector [control]="$any(draftInquiryForm).controls['from_number_of_stair_steps']"
                [fieldName]="selectors.from_number_of_stair_steps.fieldName"
                [optionsList]="selectors.from_number_of_stair_steps.options"></app-selector>

              <label class="w-checkbox checkbox-field">
                <input type="checkbox" id="checkbox-9" name="checkbox-9" data-name="Checkbox 9"
                  class="w-checkbox-input checkbox" formControlName="from_other_difficulties"><span
                  class="checkbox-label w-form-label" for="checkbox-9">... Ist anderweitig erschwert (z.B.
                  Maisonette)</span></label>
              <textarea placeholder="Bitte beschreiben" maxlength="5000" id="field-3" name="field-3" data-name="Field 3"
                class="textarea w-input" formControlName="from_other_difficulties_description">
              </textarea>
            </div>
          </div>
          <input type="submit" data-wait="bitte warten" class="submit-button w-button" value="WEITER"
            (click)="onSubmit()" style="margin-bottom: 20px;">
          <a class="movline-back-button w-button" routerLink=".." style="text-align: center;">ZURÜCK</a>
        </form>

        <pre *ngIf="environment.envName == 'local_dev'">{{draftInquiryForm.value | json}}</pre>
      </div>
    </div>
  </div>
</body>
