<h1 mat-dialog-title>
  <span>Offerieren</span>
</h1>
<div mat-dialog-content>

  <form [formGroup]="form" class="mt-4">

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.service != inquiryServiceType.CLEAN">
      <mat-label>Umzug (CHF)</mat-label>
      <input matInput formControlName="price_move">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.service == inquiryServiceType.CLEAN || data.service == inquiryServiceType.MOVE_AND_CLEAN">
      <mat-label>Reinigung (CHF)</mat-label>
      <input matInput formControlName="price_cleaning">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.services_assembling">
      <mat-label>Ab- und Aufbau (CHF)</mat-label>
      <input matInput formControlName="price_montage">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.service_trash_per_cubic">
      <mat-label>Entsorgung pro m3 (CHF)</mat-label>
      <input matInput formControlName="price_disposal">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.service_mount_lamps">
      <mat-label>Lampen montieren (CHF)</mat-label>
      <input matInput formControlName="price_service_mount_lamps">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.service_floorliner">
      <mat-label>Floorliner (CHF)</mat-label>
      <input matInput formControlName="price_service_floorliner">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.service_boxes">
      <mat-label>Kisten einpacken (CHF)</mat-label>
      <input matInput formControlName="price_service_boxes">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.services_clothes_boxes">
      <mat-label>Kleiderboxen mieten (CHF)</mat-label>
      <input matInput formControlName="price_services_clothes_boxes">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.services_furniture_lift">
      <mat-label>Möbellift (CHF)</mat-label>
      <input matInput formControlName="price_services_furniture_lift">
    </mat-form-field>

  </form>
  <!-- <pre>{{form.value | json}}</pre> -->
  <!-- <pre >{{data | json}}</pre> -->

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">Schliessen</button>
  <button mat-button color="primary" (click)="save()" cdkFocusInitial>Speichern</button>
</div>
