<ngx-spinner [name]="'statistics'" [fullScreen]="false"></ngx-spinner>
<div class="div">
  <div class="div-2">Statistiken</div>
  <img loading="lazy"
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/42cf39545e53364b4034f3cef0a6924510c9a9fe9d5498a40bfe4ac70dfc9bcc?"
    class="img" />

  <!-- <pre>
    {{statistics | json}}
  </pre>

  <pre>
    {{campaignOne.value | json}}
  </pre> -->

  <div class="div-3">

    <mat-form-field class="example-form-field" appearance="outline" style="width: 100%">
      <!-- <mat-label>First campaign</mat-label> -->
      <mat-date-range-input [formGroup]="campaignOne" [rangePicker]="campaignOnePicker">
        <input matStartDate placeholder="Start date" formControlName="start">
        <input matEndDate placeholder="End date" formControlName="end">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
    </mat-form-field>

  </div>

  <div class="div-6">
    <div class="div-7">Movline24 Statistiken</div>
    <img loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b89b9e4a5b9b27cac991ad79fcbcf57855b72c3abb5297df8263149d91b0ac22?"
      class="img-4" />
    <div class="div-8">
      <div class="div-9">
        <div class="div-10">Anfragen</div>
        <div class="div-11">{{statistics.platform.inquiries}}</div>
      </div>
      <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d8a30caaf6cf07c37ed64579cb797059491dd44019f7bbb994a8ed58a5983372?"
        class="img-5" />
      <div class="div-12">
        <div class="div-13">Buchungen</div>
        <div class="div-14">{{statistics.platform.orders}}</div>
      </div>
    </div>
    <div class="div-15">Buchungsvolumen (CHF)</div>
    <div class="div-16">{{statistics.platform.turnover | thousandsSeparator}}</div>
  </div>


  <div class="div-17">
    <div class="div-18">Deine Statistiken</div>
    <img loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b89b9e4a5b9b27cac991ad79fcbcf57855b72c3abb5297df8263149d91b0ac22?"
      class="img-6" />
    <div class="div-19">
      <div class="div-20">
        <div class="div-21">Anfragen</div>
        <div class="div-22">{{statistics.my_org.offers}}</div>
      </div>
      <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d8a30caaf6cf07c37ed64579cb797059491dd44019f7bbb994a8ed58a5983372?"
        class="img-7" />
      <div class="div-23">
        <div class="div-24">Buchungen</div>
        <div class="div-25">{{statistics.my_org.orders}}</div>
      </div>
    </div>
    <div class="div-26">Buchungsvolumen (CHF)</div>
    <div class="div-27">{{statistics.my_org.turnover | thousandsSeparator}}</div>
  </div>

  <!-- <div class="div-28">
    <div class="div-29">MOVU Statistiken</div>
    <img loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b89b9e4a5b9b27cac991ad79fcbcf57855b72c3abb5297df8263149d91b0ac22?"
      class="img-8" />
    <div class="div-30">
      <div class="div-31">
        <div class="div-32">Anfragen</div>
        <div class="div-33">437</div>
      </div>
      <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d8a30caaf6cf07c37ed64579cb797059491dd44019f7bbb994a8ed58a5983372?"
        class="img-9" />
      <div class="div-34">
        <div class="div-35">Buchungen</div>
        <div class="div-36">134</div>
      </div>
    </div>
    <div class="div-37">Buchungsvolumen (CHF)</div>
    <div class="div-38">228’048.00</div>
  </div> -->


</div>
