<body class="body">
  <app-inquiry-header [step]="4"></app-inquiry-header>
  <div class="step-1">
    <h1 class="heading">Neues Zuhause</h1>
    <p class="paragraph">Wo ziehen Sie ein?</p>
    <div class="step-1-wrapper">
      <div class="w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form"
          [formGroup]="draftInquiryForm" data-wf-page-id="65ccd238f20585c5da606c1a"
          data-wf-element-id="3c22ea9e-8587-6cdb-19ca-53e38eea0fb5">
          <div class="main-wrapper">
            <label for="Service-w-hlen" class="field-label box edit">Wo ziehen Sie ein?</label>

            <app-button-selector [control]="$any(draftInquiryForm).controls['to_type']"></app-button-selector>

            <div class="haus"><label for="Service-w-hlen-2" class="field-label box edit _20pxtop">
                <!-- Wie viele Stockwerke hat Ihr Objekt? -->
                Wie gross ist ihr Objekt?
              </label>

              <app-selector name="to_size_rooms" [control]="$any(draftInquiryForm).controls['to_size_rooms']"
                [fieldName]="selectors.number_of_rooms.fieldName"
                [optionsList]="selectors.number_of_rooms.options"></app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['to_size_square_meters']"
                [fieldName]="selectors.to_size_square_meters.fieldName"
                [optionsList]="selectors.to_size_square_meters.options"></app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['to_size_floors']"
                [fieldName]="selectors.to_size_floors.fieldName"
                [optionsList]="selectors.to_size_floors.options"></app-selector>

            </div>
            <div class="lift"><label for="Service-w-hlen-2" class="field-label box edit _20pxtop">Ist ein Lift
                vorhanden?</label>
              <div class="lift-checkbox-1">
                <label class="w-checkbox ms-select-button-2"
                  [ngClass]="{ 'selected': draftInquiryForm.get('to_type_of_elevator')?.value == '0' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div><input
                    formControlName="to_type_of_elevator" value="0" id="checkbox-8" type="radio" data-name="Checkbox 8"
                    data-ms-value="Web Design" style="opacity:0;position:absolute;z-index:-1">
                  <span class="ms-dropdown-cb-label-2 w-form-label" for="checkbox-8">Nein</span>
                </label><label class="w-checkbox ms-select-button-2"
                  [ngClass]="{ 'selected': draftInquiryForm.get('to_type_of_elevator')?.value == '1' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div><input
                    formControlName="to_type_of_elevator" value="1" id="checkbox-8" type="radio" data-name="Checkbox 8"
                    data-ms-value="Web Design" style="opacity:0;position:absolute;z-index:-1">
                  <span class="ms-dropdown-cb-label-2 w-form-label" for="checkbox-8">Ja, für 2-3 Personen</span>
                </label>
              </div>
              <div class="lift-checkbox-3"><label class="w-checkbox ms-select-button-2"
                  [ngClass]="{ 'selected': draftInquiryForm.get('to_type_of_elevator')?.value == '2' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div>
                  <input formControlName="to_type_of_elevator" value="2" id="checkbox-8" type="radio"
                    data-name="Checkbox 8" data-ms-value="Web Design"
                    style="opacity:0;position:absolute;z-index:-1"><span class="ms-dropdown-cb-label-2 w-form-label"
                    for="checkbox-8">Ja, fur 4-5 Personen</span>
                </label><label class="w-checkbox ms-select-button-2"
                  [ngClass]="{ 'selected': draftInquiryForm.get('to_type_of_elevator')?.value == '3' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div><input
                    formControlName="to_type_of_elevator" value="3" id="checkbox-8" type="radio" data-name="Checkbox 8"
                    data-ms-value="Web Design" style="opacity:0;position:absolute;z-index:-1"><span
                    class="ms-dropdown-cb-label-2 w-form-label" for="checkbox-8">Ja, für 6+ Personen</span>
                </label></div>
              <div class="lift-checkbox-3">
                <label class="w-checkbox ms-select-button-2 _100"
                  [ngClass]="{ 'selected': draftInquiryForm.get('to_type_of_elevator')?.value == '4' }">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb"></div>
                  <input formControlName="to_type_of_elevator" value="4" id="checkbox-8" type="radio"
                    data-name="Checkbox 8" data-ms-value="Web Design"
                    style="opacity:0;position:absolute;z-index:-1"><span class="ms-dropdown-cb-label-2 w-form-label"
                    for="checkbox-8">Ja, Warenlift für 10+ Personen</span>
                </label>
              </div>
            </div>
            <div class="parkplatz"><label for="Service-w-hlen-2" class="field-label box edit _20pxtop">Weg vom Parkplatz
                zum Gebäude</label><label class="w-checkbox checkbox-field"><input type="checkbox" id="checkbox-9"
                  formControlName="to_has_more_than_5_meters" name="checkbox-9" data-name="Checkbox 9"
                  class="w-checkbox-input checkbox"><span class="checkbox-label w-form-label" for="checkbox-9">... ist
                  langer als 5 Meter</span></label>

              <app-selector [control]="$any(draftInquiryForm).controls['to_way_from_parking_meters']"
                [fieldName]="selectors.to_way_from_parking_meters.fieldName" [optionsList]="selectors.to_way_from_parking_meters.options">
              </app-selector>

              <label class="w-checkbox checkbox-field">
                <input type="checkbox" id="checkbox-9" name="checkbox-9" formControlName="to_has_stair_steps"
                  data-name="Checkbox 9" class="w-checkbox-input checkbox">
                <span class="checkbox-label w-form-label" for="checkbox-9">... hat
                  Stufen</span>
              </label>

              <app-selector [control]="$any(draftInquiryForm).controls['to_number_of_stair_steps']"
                [fieldName]="selectors.to_number_of_stair_steps.fieldName" [optionsList]="selectors.to_number_of_stair_steps.options">
              </app-selector>

              <label class="w-checkbox checkbox-field">
                <input type="checkbox" id="checkbox-9" name="checkbox-9" formControlName="to_other_difficulties"
                  data-name="Checkbox 9" class="w-checkbox-input checkbox">
                <span class="checkbox-label w-form-label" for="checkbox-9">... Ist anderweitig erschwert (z.B.
                  Maisonette)</span>
              </label>

              <textarea formControlName="to_other_difficulties_description" placeholder="Bitte beschreiben"
                maxlength="5000" id="field-3" name="field-3" data-name="Field 3" class="textarea w-input"></textarea>
            </div>
            <div class="karton"><label for="Service-w-hlen" class="field-label box edit _20pxtop">Wie viele Personen
                ziehen um und wie viele Kisten und Taschen müssen insgesamt ca. gezügelt werden?</label>

              <app-selector [control]="$any(draftInquiryForm).controls['number_of_persons']"
                [fieldName]="selectors.number_of_persons.fieldName" [optionsList]="selectors.number_of_persons.options">
              </app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['number_of_cartons']"
                [fieldName]="selectors.number_of_cartons.fieldName" [optionsList]="selectors.number_of_cartons.options">
              </app-selector>

              <div class="infobox">
                <p class="text-karton-small">Wir empfehlen</p>
                <p class="paragraph-6">30 pro Person</p>
                <p class="text-karton-klein">In der Regel benötigen Sie 30 Umzugskartons, Taschen oder Koffer pro Person
                </p>
                <div class="_100"></div>
                <p class="text-karton-small title">Wir empfehlen</p>
                <p class="text-karton-klein">Bitte teilen Sie uns vor dem Umzug Ihre endgültige Anzahl an Kartons mit,
                  da eine höhere Menge an Kartons zu einem Mehraufwand führen kann.</p>
              </div><label for="Service-w-hlen" class="field-label box edit _20pxtop nostrich">Berechnen Sie die
                ungefähre Anzahl der Umzugskartons</label>
            </div>
            <div class="umzugsservice"><label for="Service-w-hlen"
                class="field-label box edit _20pxtop nobottom">Umzugsservices</label>
              <p class="text-karton-klein">Wahlen Sie hier bequem Services aus, welche Ihren Umzug zusätzlich
                erleichtern. </p>
              <div id="w-node-_563837a9-6d22-576c-3a0d-aede3fc00bc7-da606c1a"
                class="w-layout-layout quick-stack-2 wf-layout-layout">
                <div id="w-node-_563837a9-6d22-576c-3a0d-aede3fc00bc8-da606c1a" class="w-layout-cell"><label
                    class="w-checkbox">
                    <input type="checkbox" id="checkbox-10" name="checkbox-10" data-name="Checkbox 10"
                      class="w-checkbox-input" formControlName="services_assembling">
                    <span class="w-form-label" for="checkbox-10">Ab-
                      und Aufbau Ihrer Möbel</span>
                  </label>
                </div>
                <div id="w-node-_2110c9c8-e7f1-98c2-486b-d94a88eea017-da606c1a" class="w-layout-cell"><label
                    class="w-checkbox">
                    <input type="checkbox" id="checkbox-10" name="checkbox-10" formControlName="services_furniture_lift"
                      data-name="Checkbox 10" class="w-checkbox-input"><span class="w-form-label"
                      for="checkbox-10">Möbellift</span></label></div>
                <div id="w-node-c4c717ce-9a5b-d416-6822-e3ec9ccc4d46-da606c1a" class="w-layout-cell"><label
                    class="w-checkbox">
                    <input type="checkbox" id="checkbox-10" name="checkbox-10" data-name="Checkbox 10"
                      formControlName="services_clothes_boxes" class="w-checkbox-input"><span class="w-form-label"
                      for="checkbox-10">Kleiderboxen mieten (inkl.
                      Lieferung am Umzugstag &amp; Abholung)</span></label>
                    <p class="paragraph-7">Wie viele Kleiderboxen werden benötigt?</p>
                    <input formControlName="service_number_of_clothes_boxes" class="text-field-4 w-input" maxlength="256" name="field-4"
                      data-name="Field 4" placeholder="3 Lampen" type="number" id="field-4" required="">
                </div>
                <div id="w-node-f8e2d870-b6be-9458-b219-4c28491edf94-da606c1a" class="w-layout-cell"><label
                    class="w-checkbox">
                    <input type="checkbox" id="checkbox-10" name="checkbox-10" data-name="Checkbox 10"
                      formControlName="service_boxes" class="w-checkbox-input"><span class="w-form-label"
                      for="checkbox-10">Kisten einpacken (inkl.
                      Kisten)</span></label></div>
                <div id="w-node-_90537aa4-147e-1e00-5677-8bd56efe1b46-da606c1a" class="w-layout-cell"><label
                    class="w-checkbox">
                    <input type="checkbox" id="checkbox-10" name="checkbox-10" formControlName="service_mount_lamps"
                      data-name="Checkbox 10" class="w-checkbox-input"><span class="w-form-label"
                      for="checkbox-10">Lampen abmontieren</span></label>
                  <p class="paragraph-7">Wie viele Lampen müssen abmontiert werden</p>

                  <input formControlName="service_number_of_lamps" class="text-field-4 w-input" maxlength="256"
                    name="field-4" data-name="Field 4" placeholder="3 Lampen" type="number" id="field-4" required="">
                </div>
                <div id="w-node-_26b08601-7460-aa7d-89ba-2c0925f3cec5-da606c1a" class="w-layout-cell"><label
                    class="w-checkbox">
                    <input type="checkbox" id="checkbox-10" name="checkbox-10" formControlName="service_trash_per_cubic"
                      data-name="Checkbox 10" class="w-checkbox-input"><span class="w-form-label"
                      for="checkbox-10">Entsorgung pro m3</span></label></div>
                <div id="w-node-abdb9cad-935f-e8bc-510e-dee3a398e361-da606c1a" class="w-layout-cell"><label
                    class="w-checkbox">
                    <input type="checkbox" id="checkbox-10" name="checkbox-10" data-name="Checkbox 10"
                      formControlName="service_floorliner" class="w-checkbox-input"><span class="w-form-label"
                      for="checkbox-10">Floorliner/Bodenschutz
                      bestellen</span></label>
                </div>
              </div>
            </div>
          </div>
          <input (click)="onSubmit()" type="submit" data-wait="bitte warten" class="submit-button w-button"
            value="WEITER" style="margin-bottom: 20px;">
          <a class="movline-back-button w-button" [routerLink]="['..']" style="text-align: center;">ZURÜCK</a>

        </form>

        <pre *ngIf="environment.envName == 'local_dev'">{{draftInquiryForm.value | json}}</pre>

      </div>
    </div>
  </div>

</body>
