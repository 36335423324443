<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1>Alle Offerten</h1>
      </div>

      <!-- <div class="filter">
        <label>
          <input type="radio" [(ngModel)]="offerTypeFilter" value="active" (change)="applyFilter()" /> Active
        </label>
        <label>
          <input type="radio" [(ngModel)]="offerTypeFilter" value="all" (change)="applyFilter()" /> All
        </label>
      </div> -->

      <div *ngIf="!isLoading && items.length == 0 ">
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
        <div class="alert alert-info mt-2">Keine Offerten</div>
      </div>

      <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
        <thead>
          <td>Datum des Auftrages</td>
          <td>Angebotsdatum</td>
          <td>Von</td>
          <td>Nach</td>
          <td>Status</td>
          <td>Organization</td>
          <!-- <td>Bid (CHF)</td> -->
          <td></td>
        </thead>

        <tbody>
          <tr *ngFor="let item of items">

            <td><span *ngIf="item.inquiry_object">{{item.inquiry_object.moving_date}}</span></td>
            <td>{{item.updated_at | humanDatePipe}}</td>
            <td><span *ngIf="item.inquiry_object">{{item.inquiry_object.from_location}}</span></td>
            <td><span *ngIf="item.inquiry_object">{{item.inquiry_object.to_location}}</span></td>
            <td><span *ngIf="item.inquiry_object">{{item.inquiry_object.status | inquiryStatusPipe}}</span></td>
            <td><span *ngIf="item.organization_object">{{item.organization_object.name}}</span></td>
            <!-- <td>{{item.total_price | thousandsSeparator}}</td> -->
            <td>
              <a routerLink="/main/admin/inquiries/{{item.inquiry_object.uuid}}" *ngIf="item.inquiry_object">Details</a>
            </td>

          </tr>
        </tbody>
      </table>

      <pre *ngIf="environment.envName == 'local_dev'">{{items | json}}</pre>

    </mat-card-content>
  </mat-card>
</div>
