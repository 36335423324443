import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InquiriesService } from 'projects/webapp/app/services/inquiries.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-new-inquiries',
  templateUrl: './new-inquiries.component.html',
  styleUrls: ['./new-inquiries.component.scss']
})
export class NewInquiriesComponent implements OnInit {


  items = []
  isLoading = false;

  constructor(
    private title: Title,
    private router: Router,
    private inquiriesService: InquiriesService
  ) {
    this.title.setTitle('Movline24: Neue Anfragen');
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    this.isLoading = true;
    try {
      this.items = await lastValueFrom(this.inquiriesService.getInquiries());
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

  onClickDetails(inquiryUUID: string) {
    this.router.navigate(['/main/inquiries/' + inquiryUUID])
  }
}
