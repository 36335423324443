import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-furniture",
  template: `
    <form [formGroup]="furnitureForm">
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <label for="M-belst-ck">Möbelstück </label>
      <a for="M-belst-ck" style="color: red; font-weight: lighter" *ngIf="index > 0" [routerLink]="[]" (click)="onDelete()">Möbelstück löschen</a>
    </div>
      <input class="field-round text-field-6 w-input" maxlength="256" name="M-belst-ck" data-name="Möbelstück"
      placeholder="z.b. Sofa, Pflanze, Tisch, " type="text" id="M-belst-ck" formControlName="name">

      <label for="Anzahl-4">Anzahl</label>
      <input class="field-round text-field-6 w-input" maxlength="256" name="Anzahl" data-name="Anzahl"
      placeholder="" type="number" id="Anzahl-4"
      formControlName="number"
      >

      <label for="Weitere-Details">weitere Details </label>
      <input class="field-round text-field-6 w-input" maxlength="256" name="Weitere-Details"
      data-name="Weitere Details" placeholder="z.b. es handelt sich um einen Glastisch" type="text"
      id="Weitere-Details"
      formControlName="details"
      >
    </form>
  `,
  styles: [ ],
})
export class FurnitureComponent {

  @Input() furnitureForm: any;
  @Input() index: number = 0;
  @Input() number_of_furnitures: number = 0;

  @Output() deleteFurniture = new EventEmitter<number>()

  ngInit(){
  }

  onDelete() {
    this.deleteFurniture.emit(this.index)
  }
 }

