<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
        <h1>Meine Aufträge</h1>
      </div>

      <div *ngIf="!isLoading && items.length == 0 ">
        <div class="alert alert-info">Keine Aufträge bisher</div>
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
      </div>

      <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
        <thead>
          <td>Id</td>
          <td>Von</td>
          <td>Nach</td>
          <td>Status</td>
          <td>Umsatz (CHF)</td>
          <td>Zahlung</td>
          <td></td>
        </thead>

        <tbody>
          <tr *ngFor="let item of items">

            <td># {{item.id}}</td>
            <td>{{item.offer_object.inquiry_object.from_location}}</td>
            <td>{{item.offer_object.inquiry_object.to_location }}</td>
            <td> {{item.status | orderStatePipe }} </td>
            <td>{{item.total_price | thousandsSeparator }}</td>
            <td>{{item.payment | orderPaymentTypePipe }}</td>
            <td>
              <a routerLink="/main/my-orders/{{item.uuid}}" class="button">Details</a>

            </td>

          </tr>
        </tbody>
      </table>

      <!-- <pre>{{items | json}}</pre> -->

    </mat-card-content>

  </mat-card>
</div>
