<ngx-spinner type="ball-scale-multiple">
  <button mat-flat-button color="primary" class="mt-3" (click)="cancelLoading()">Abbrechen</button>
</ngx-spinner>

<div class="main-container">
  <app-header (menuToggled)="toggle($event)"></app-header>

  <mat-drawer-container>
    <mat-drawer mode="side" #leftSideNav [opened]="opened">
      <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
        <div>
          <app-menu-item [menu]="menu" (elementClicked)="menuItemClicked($event)"></app-menu-item>
          <hr *ngIf="authService.isAuthenticatedPlatformAdmin()">
          <small *ngIf="authService.isAuthenticatedPlatformAdmin()" style="padding-left: 16px;">Admin area</small>
          <app-menu-item [menu]="menuAdmin" (elementClicked)="menuItemClicked($event)" *ngIf="authService.isAuthenticatedPlatformAdmin()"></app-menu-item>
        </div>
        <!-- <small style="align-self: center;" class="pb-3">{{(organizationsService.ownOrg$ | async)?.name}}</small> -->

        <div style="display: flex; align-items: center; flex-direction:column;">
          <app-side-bar-bottom></app-side-bar-bottom>
          <small style="align-self: center;" class="pb-3">© Movline GmbH</small>
        </div>
      </div>
    </mat-drawer>


    <mat-drawer-content [class.margin-left]="opened">
      <router-outlet></router-outlet>
      <!-- <pre>{{myUser | json}}</pre> -->
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
