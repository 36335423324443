import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'projects/webapp/app/services/dashboard.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-my-offers',
  templateUrl: './my-offers.component.html',
  styleUrls: ['./my-offers.component.scss']
})
export class MyOffersComponent implements OnInit {
  items: any[] = []

  isLoading = false;

  constructor(
    private title: Title,
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.title.setTitle('Movline24: Meine Offerten');
  }

  async ngOnInit() {
    this.refresh();
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      this.items = await lastValueFrom(this.dashboardService.getOffers())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }
}
