<div class="container-fluid" *ngIf="user">

  <div class="d-flex justify-content-between">
    <h1>User: {{user.first_name}} {{user.last_name}}</h1>

    <div>
      <a mat-icon-button [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
      <a mat-icon-button [routerLink]="[]" (click)="toggleLock()">
        <mat-icon *ngIf="!user.is_active">lock</mat-icon>
        <mat-icon *ngIf="user.is_active">lock_open</mat-icon>
      </a>
      <a mat-icon-button href="{{environment.apiDomain}}/admin/accounts/loginuser/{{user.id}}/change/" target="_blank">
        <mat-icon>edit</mat-icon>
      </a>
    </div>
  </div>

  <hr>

  <div>
    <h2>Details</h2>

    <div class="alert alert-warning" role="alert" *ngIf="!user.is_active">
      Blocked User
    </div>

    <ul class="co-simple-list">
      <li>first_name: {{user.first_name}}</li>
      <li>last_name: {{user.last_name}}</li>

      <li>username:{{user.username}}</li>
      <li>email: {{user.email}}</li>

      <li>phone: {{user.phone}}</li>
      <li>Organization: <a
          routerLink="/main/admin/organizations/{{user.organization_object.uuid}}">{{user.organization_object.name}}</a>
      </li>

      <li>last_login: {{user.last_login | humanDatePipe}}</li>

      <li>created_at: {{user.created_at | humanDatePipe}}</li>
      <li>updated_at: {{user.updated_at | humanDatePipe}}</li>

      <li>uuid: {{user.uuid}}</li>

    </ul>


  </div>

  <hr>

  <div style="display: flex; flex-direction: column;">


  </div>


</div>
