import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';


export interface ISelectorOption {
  value: string | number;
  display: string;
}

@Component({
  selector: 'app-button-selector',
  templateUrl: './button-selector.component.html',
  styleUrls: ['./button-selector.component.scss']
})
export class ButtonSelectorComponent implements OnInit {

  @Input() control: FormControl | undefined;

  constructor(
  ) { }

  ngOnInit(): void { }

  changePropertyValue(type: string) {
    if (this.control){
      this.control.setValue(type)
    }
  }

}
