<ngx-spinner [name]="'next-works'" [fullScreen]="false"></ngx-spinner>

<div>
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h5>{{title}}</h5>
    <button mat-icon-button (click)="refreshComments()" target="_blank">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <div *ngIf="canAddNewComment">
    <textarea [(ngModel)]="newComment" style="width: 100%;" cols="10"></textarea>
    <br>
    <button (click)="onAddNewComment()" class="btn btn-primary" [disabled]="newComment === ''">Add Comment</button>
  </div>

  <div class="alert alert-info mt-2 mb-2" *ngIf="comments.length == 0">Noch keine Kommentare</div>

  <table *ngIf="!!comments && comments.length" style="width: 100%" class="table table-striped">
    <thead>
      <td>Who</td>
      <td>When</td>
      <td>What</td>
    </thead>

    <tbody>

      <tr *ngFor="let item of comments">
        <td>{{item.author_object?.first_name}} {{item.author_object?.last_name}}</td>
        <td>{{item.updated_at | humanDatePipe}}</td>
        <td>{{item.content}}</td>
      </tr>

    </tbody>
  </table>
</div>
