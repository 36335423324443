<ngx-spinner [name]="'next-works'" [fullScreen]="false"></ngx-spinner>

<div class="div">
  <div class="div-2">Nächste Termine</div>

  <img loading="lazy"
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/42cf39545e53364b4034f3cef0a6924510c9a9fe9d5498a40bfe4ac70dfc9bcc?"
    class="img" />

  <!-- <pre>
      {{nextWorks | json}}
  </pre> -->

  <div *ngFor="let day of dayList">

    <div class="div-3"><span style="font-weight: 700">{{day.formatted_date}}</span></div>

      <!-- <pre>
            {{day | json}}
        </pre> -->

    <div class="div-4" *ngIf="!day.work_items" style="width: 100%;">Keine Ereignisse</div>
    <div class="div-8" *ngFor="let item of day.work_items">
      <!-- <img loading="lazy" srcset="..." class="img-2" />
      <div class="div-9"></div>
      <div class="div-10"></div> -->
      <a href="/main/my-orders/{{item.uuid}}" class="div-11">{{item.offer_object.inquiry_object.from_location}} / {{item.offer_object.inquiry_object.to_location}} ( #{{item.id}} )</a>
      <!-- <div class="div-12">M</div> -->
    </div>
  </div>
<!--
  <div class="div-3"><span style="font-weight: 700">Dienstag</span></div>
  <div class="div-4">Keine Ereignisse</div>
  <div class="div-5"><span style="font-weight: 700">Dienstag</span></div>
  <div class="div-6">Keine Ereignisse</div>
  <div class="div-7"><span style="font-weight: 700">Dienstag</span></div>
  <div class="div-8">
    <div class="div-9"></div>
    <div class="div-10"></div>
    <div class="div-11">Michell (#000000)</div>
    <div class="div-12">M</div>
  </div>
  <div class="div-13">
    <div class="div-14"></div>
    <div class="div-15"></div>
    <div class="div-16">Michell (#000000)</div>
    <div class="div-17">M</div>
  </div>
  <div class="div-18"><span style="font-weight: 700">Dienstag</span></div>
  <div class="div-19">
    <div class="div-20"></div>
    <div class="div-21"></div>
    <div class="div-22">Michell (#000000)</div>
    <div class="div-23">M</div>
  </div>
  <div class="div-24"><span style="font-weight: 700">Dienstag</span></div>
  <div class="div-25">Keine Ereignisse</div>
  <div class="div-26"><span style="font-weight: 700">Dienstag</span></div>
  <div class="div-27">Keine Ereignisse</div>
  <div class="div-28"><span style="font-weight: 700">Dienstag</span></div>
  <div class="div-29">
    <div class="div-30"></div>
    <div class="div-31"></div>
    <div class="div-32">Michell (#000000)</div>
    <div class="div-33">M</div>
  </div>
  <div class="div-34">
    <div class="div-35"></div>
    <div class="div-36"></div>
    <div class="div-37">Michell (#000000)</div>
    <div class="div-38">M</div>
  </div>
  <div class="div-39"><span style="font-weight: 700">Dienstag</span></div>
  <div class="div-40">Keine Ereignisse</div> -->
</div>

<!-- <pre>{{dayList | json}}</pre> -->
