import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDetailedInquiry, InquiryServiceType, InquiryStatus } from 'projects/webapp/app/interfaces/inquiry.interface';
import { ActivatedRoute } from '@angular/router';
import { InquiriesService } from 'projects/webapp/app/services/inquiries.service';
import { MatDialog } from '@angular/material/dialog';
import { BidCardModalComponent } from 'projects/webapp/app/components/bid-card-modal/bid-card-modal.component';
import { DashboardService } from 'projects/webapp/app/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { AuthService } from 'projects/webapp/app/services/auth.service';


@Component({
  selector: 'app-inquiry-details',
  templateUrl: './inquiry-details.component.html',
  styleUrls: ['./inquiry-details.component.scss']
})
export class InquiryDetailsComponent implements OnInit {

  public isLoading = false;
  item: IDetailedInquiry | null = null
  offers: any | null = null
  inquiryUUID: string | null = null;

  inquiryStatus = InquiryStatus;
  inquiryServiceType = InquiryServiceType
  environment = environment

  constructor(
    private title: Title,
    private inquiriesService: InquiriesService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    public authService: AuthService
  ) { }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Details der Anfrage`);
    this.route.params.subscribe(params => {
      if (!!params && params['inquiryUUID']) {
        this.inquiryUUID = params['inquiryUUID'];
        this.refresh();
      }
    });
  }

  async refresh() {
    try {
      this.spinnerService.show();
      if (this.inquiryUUID) {
        this.item = await lastValueFrom(this.inquiriesService.getInquiry(this.inquiryUUID));
        this.offers = await lastValueFrom(this.dashboardService.getOffers({ inquiry: this.inquiryUUID }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }

  doOffer() {
    console.log('do offer');
    const data = this.item;
    this.dialog.open(BidCardModalComponent, {data: data}).afterClosed().subscribe(async (val) => {
      console.log(val);

      if (!!val && this.inquiryUUID) {
        try {
          this.spinnerService.show();
          await lastValueFrom(this.dashboardService.createBid(this.inquiryUUID, val));
          this.toastrService.success('Offerte eingereicht! Sie werden bald weiter informiert!')
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
          this.refresh()
        }
      }
    })
  }
}
