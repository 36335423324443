import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'projects/webapp/app/environments/environment';
import { IDetailedInquiry, InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';

@Component({
  selector: "inquiry-card-component",
  templateUrl: './inquiry-card.component.html',
  styleUrls: ['./inquiry-card.component.scss']
})
export class InquiryCardComponent implements OnInit {

  services = [
    { "service_trash_per_cubic": "Entsorgung" },
    { "service_mount_lamps": "Lampenmontage" },
    { "service_floorliner": "Floorliner" },
    { "service_boxes": "Kisten einpacken" },
    { "services_clothes_boxes": "Kleiderboxen mieten" },
    { "services_furniture_lift": "Möbellift" },
    { "services_assembling": "Montageservice" },
  ]

  @Input() item: IDetailedInquiry | null = null;

  @Output() onClickDetails = new EventEmitter<string>()

  inquiryServiceType = InquiryServiceType;
  environment = environment;

  numberOfFurniture = 0;

  constructor() {
  }

  ngOnInit() {
    this.numberOfFurniture = 0;
    try {
      if (this.item?.inventar) {
        for (let index = 0; index < this.item?.inventar.length; index++) {
          const room = this.item?.inventar[index];
          for (let forniture_index = 0; index < room.furniture.length; forniture_index++) {
            const forniture = room.furniture[forniture_index];
            this.numberOfFurniture += forniture.number
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  navigateToDetails() {
    if (this.item && this.item.uuid) {
      this.onClickDetails.emit(this.item.uuid)
    }
  }

  serviceName(service: any) {
    const inquiry = this.item as any;
    console.log(service)
    if (inquiry[Object.keys(service)[0]]) {
      for (let index = 0; index < this.services.length; index++) {
        const element = this.services[index] as any;
        if (Object.keys(element)[0] == Object.keys(service)[0]) {
          return element[Object.keys(service)[0]]
        }
      }
    }
    return '-';
  }

  hasService(service: any): any {
    const inquiry = this.item as any;
    console.log(service)
    if (inquiry[Object.keys(service)[0]]) {
      // console.log(`inquiry[Object.keys(service)[0]]: ${inquiry[Object.keys(service)[0]]}`)
      return true;
    }
    return false;
  }


}
