<div class="page-container pb-4 mb-5">

  <div class="container mb-5">


    <mat-card class="mt-4 mb-4">
      <mat-card-content>

        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h1>Meine Organization</h1>
        </div>

        <form [formGroup]="organizationForm" (ngSubmit)="submitOrganization()" class="mt-4">

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Firmenname </mat-label>
            <input matInput formControlName="company_name">
          </mat-form-field>

          <div class="row">
            <span class="mb-3">Kontaktperson</span>
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Vorname </mat-label>
              <input matInput formControlName="first_name">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Nachname</mat-label>
              <input matInput formControlName="last_name">
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>E-Mail</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>

          <div class="row">
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Telefon</mat-label>
              <input matInput formControlName="phone">
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Website</mat-label>
              <input matInput formControlName="website">
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Adresse </mat-label>
            <input matInput formControlName="address">
          </mat-form-field>

          <div class="row">

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Postleitzahl</mat-label>
              <input matInput formControlName="zip">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Ortschaft</mat-label>
              <input matInput formControlName="location">
            </mat-form-field>

          </div>

          <div class="row">
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>MWST-Nummer</mat-label>
              <input matInput formControlName="vat_number">
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>IBAN</mat-label>
              <input matInput formControlName="iban_number">
            </mat-form-field>
          </div>

          <div class="d-flex justify-content-end">
            <button mat-flat-button color="primary"> Speichern </button>
          </div>

        </form>
      </mat-card-content>
    </mat-card>

  </div>

</div>
