import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.scss']
})
export class MyPaymentsComponent implements OnInit {
  isLoading = false;
  items = []

  constructor(
    private title: Title,
  ) {
    this.title.setTitle('Movline24: Meine Zahlungen');
  }

  async ngOnInit() {
  }
}
