import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IDetailedUser, UsersService } from 'projects/webapp/app/services/users.service';


export interface MenuItem {
  title: string;
  icon: string;
  link: string;
  color: string;
  expanded?: boolean;
  subMenu?: MenuItem[];
}

export type Menu = MenuItem[];


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  opened = true;

  myUser: IDetailedUser | null = null;
  @ViewChild('leftSideNav') public leftSideNav: any;
  menu: Menu = [
  ];

  menuAdmin: Menu = [
    {
      title: 'Alle Orgs',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/organizations',
    },
    {
      title: 'Alle Benutzer',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/users',
    },

    {
      title: 'alle Anfragen',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/inquiries',
    },
    {
      title: 'alle Offerten',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/offers',
    },
    {
      title: 'alle Aufträge',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/orders',
    },
    {
      title: 'alle Bewertungen',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/ratings',
    },
    {
      title: 'Activity',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/activity',
    },
  ];

  constructor(
    public authService: AuthService,
    public usersService: UsersService,
    public organizationsService: OrganizationsService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
  ) {
    this.checkIfNeedsToCloseListsPanel(false);
    this.usersService.initService();
    this.organizationsService.initService();
    this.route.url.subscribe(val => {
      this.refreshMenuItems()
    });
  }

  async ngOnInit() {
    this.usersService.ownUser$.asObservable().subscribe(val => {
      this.myUser = val;
      this.refreshMenuItems()
    })
  }

  refreshMenuItems() {
    if (!!this.myUser) {
      let menu: any[] = [
        {
          title: 'Übersicht',
          icon: 'home',
          // icon: 'dashboard',
          link: '/main/dashboard',
          color: '#146160',
        },
      ]

      // menu.push(
      //   {
      //     title: 'Kunde erfassen',
      //     icon: 'place',
      //     link: '/main/client-editor/new',
      //     color: '#146160',
      //   },
      // )

      menu.push(
        {
          title: 'Neue Anfragen',
          icon: 'message',
          link: '/main/new-inquiries',
          color: '#146160',
        },
      )

      menu.push(
        {
          title: 'Meine Offerten',
          icon: 'assignment_ind',
          link: '/main/my-offers',
          color: '#146160',
        },
      )

      menu.push(
        {
          title: 'Meine Aufträge',
          icon: 'assignment_turned_in',
          link: '/main/my-orders',
          color: '#146160',
        },
      )

      // menu.push(
      //   {
      //     title: 'Zahlungsübersicht',
      //     icon: 'place',
      //     link: '/main/my-payments',
      //     color: '#146160',
      //   },
      // )

      menu.push(
        {
          title: 'Meine Bewertungen',
          icon: 'stars',
          link: '/main/my-ratings',
          color: '#146160',
        },
      )

      this.menu = menu;

    }
  }

  toggle(event: any): void {
    if (!event && this.opened) {
      this.opened = false;
    } else {
      this.opened = !this.opened;
    }
  }

  checkIfNeedsToCloseListsPanel(withOpen = false): void {
    setTimeout(() => {
      if (window.innerWidth < 701) {
        console.log('smaller than 700');
        // this.leftSideNav.close();
        this.opened = false;
      }
      if (withOpen) {
        this.opened = true;
        // this.leftSideNav.open();
      }
    }, 500);
  }

  // private refreshShipperMenuItems() {
  //   if (!!this.myUser) {

  //     let menu: MenuItem[] = [
  //       {
  //         title: 'Dashboard',
  //         icon: 'dashboard',
  //         link: '/main/shipper/dashboard',
  //         color: '#146160',

  //       },
  //     ]

  //     if (
  //       this.myUser.permission_market_place &&
  //       this.organizationsService.ownOrg$.value?.have_market_place &&
  //       this.myUser.preference_show_market_place
  //     ) {
  //       menu.push(
  //         {
  //           title: 'Marktplatz',
  //           icon: 'place',
  //           link: '/main/shipper/market-place',
  //           color: '#146160',

  //         },
  //       )
  //     }

  //     menu.push(
  //       {
  //         title: 'Sendungsverwaltung',
  //         icon: 'label',
  //         link: '/main/shipper/shipment-list',
  //         color: '#146160',

  //       },
  //     )

  //     menu.push(
  //       {
  //         title: 'Inserate',
  //         icon: 'wallpaper',
  //         link: '/main/shipper/adcard',
  //         color: '#146160',


  //       },
  //     )
  //     // menu.push(
  //     //   {
  //     //     title: 'Rampenverwaltung',
  //     //     icon: 'dock',
  //     //     link: '/main/shipper/dock-yard-management',
  //     //     color: '#146160',
  //     //   },
  //     // )

  //     // if (this.myUser.permission_export) {
  //     //   menu.push(
  //     //     {
  //     //       title: 'Export',
  //     //       icon: 'import_export',
  //     //       link: '/main/shipper/export',
  //     //       color: '#146160',
  //     //     },
  //     //   )
  //     // }

  //     menu.push(
  //       {
  //         title: 'Suchen',
  //         icon: 'search',
  //         link: '/main/shipper/search',
  //         color: '#146160',

  //       },
  //     )

  //     // if (this.myUser.permission_settings) {
  //     //   menu.push(
  //     //     {
  //     //       title: 'Einstellungen',
  //     //       icon: 'library_books',
  //     //       link: '/main/shipper/settings',
  //     //       color: '#146160',
  //     //     },
  //     //   )
  //     // }

  //     this.menu = menu;
  //   }
  // }

  // private refreshCarrierMenuItems() {
  //   if (!!this.myUser) {
  //     let menu: Menu = [
  //       {
  //         title: 'Dashboard',
  //         icon: 'dashboard',
  //         link: '/main/carrier/dashboard',
  //         color: '#146160',

  //       }
  //     ]

  //     if (this.myUser.permission_dispatch) {
  //       menu.push(
  //         {
  //           title: 'Importieren',
  //           icon: 'label_important',
  //           link: '/main/carrier/import-shipments',
  //           color: '#146160',

  //         },
  //       )
  //     }

  //     if (this.myUser.permission_pipeline) {
  //       menu.push({
  //         title: 'Freigabepipeline',
  //         icon: 'local_offer',
  //         link: '/main/carrier/pipeline-release',
  //         color: '#146160',

  //       })
  //     }

  //     if (this.myUser.permission_dispatch) {
  //       menu.push(
  //         {
  //           title: 'Dispositionspipeline',
  //           icon: 'calendar_today',
  //           // link: '/main/carrier/pipeline-dispatch-v2',
  //           link: '/main/carrier/pipeline-dispatch',
  //           color: '#146160',


  //         },
  //       )
  //     }

  //     if (this.myUser.permission_invoicing) {
  //       menu.push(
  //         {
  //           title: 'Fakturierungspipeline',
  //           icon: 'attach_money',
  //           link: '/main/carrier/pipeline-invoice',
  //           color: '#146160',


  //         },
  //       )
  //       if (this.myUser.preference_show_facturation) {
  //         menu.push(
  //           {
  //             title: 'Fakturierung',
  //             icon: 'account_balance',
  //             link: '/main/carrier/facturation',
  //             color: '#146160',


  //           },
  //         )
  //       }
  //     }

  //     if (
  //       this.organizationsService.ownOrg$.value?.have_market_place &&
  //       this.myUser.permission_market_place &&
  //       this.myUser.preference_show_shipments_to_pickup
  //     ) {
  //       menu.push(
  //         {
  //           title: 'Marktplatz',
  //           icon: 'place',
  //           link: '/main/carrier/market-place',
  //           color: '#146160',


  //         },
  //       )
  //       menu.push(
  //         {
  //           title: 'Inserate',
  //           icon: 'wallpaper',
  //           link: '/main/carrier/adcard',
  //           color: '#146160',


  //         },
  //       )
  //     }

  //     if (this.myUser.permission_client_management) {
  //       menu.push(
  //         {
  //           title: 'Kundenverwaltung',
  //           icon: 'supervised_user_circle',
  //           link: '/main/carrier/clients',
  //           color: '#146160',


  //         },
  //       )
  //     }

  //     if (this.myUser.permission_dispatch) {
  //       menu.push(
  //         {
  //           title: 'Sendungsverwaltung',
  //           icon: 'label',
  //           link: '/main/carrier/shipment-list',
  //           color: '#146160',

  //         },
  //       )
  //     }

  //     if (this.myUser.permission_activity && this.myUser.preference_show_activity) {
  //       menu.push(
  //         {
  //           title: 'Aktivität',
  //           icon: 'remove_red_eye',
  //           link: '/main/carrier/activity',
  //           color: '#146160',


  //         },
  //       )
  //     }

  //     if (this.myUser.permission_export) {
  //       menu.push(
  //         {
  //           title: 'Export',
  //           icon: 'import_export',
  //           link: '/main/carrier/export',
  //           color: '#146160',


  //         },
  //       )
  //     }

  //     menu.push(
  //       {
  //         title: 'Suchen',
  //         icon: 'search',
  //         link: '/main/carrier/search',
  //         color: '#146160',


  //       },
  //     )

  //     if (this.myUser.permission_settings) {
  //       menu.push(
  //         {
  //           title: 'Einstellungen',
  //           icon: 'library_books',
  //           link: '/main/carrier/settings',
  //           color: '#146160',

  //         },
  //       )
  //     }

  //     this.menu = menu;

  //   }
  // }


  menuItemClicked(menuItem: MenuItem) {
    console.log(menuItem)
    // if (menuItem.needsSubscription && !this.organizationsService.ownOrg$.value?.admin_permission_software_license) {
    //   this.dialog.open(LicenseModalComponent,
    //     { disableClose: true }
    //   ).afterClosed().subscribe(() => {
    //     this.router.navigate(['/main/carrier'])
    //   })
    //   return
    // }
    this.checkIfNeedsToCloseListsPanel(false);
  }


  cancelLoading() {
    this.spinnerService.hide()
  }

}
