import { Component, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { outputErrors } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';

export const FROM_SIZE_SQUARE_METERS = [
  { "value": "1", display: "50m2" },
  { "value": "2", display: "70m2" },
  { "value": "3", display: "90m2" },
  { "value": "4", display: "110m2" },
  { "value": "5", display: "130m2" },
  { "value": "6", display: "150m2" },
  { "value": "7", display: "170m2" },
  { "value": "8", display: "190m2" },
  { "value": "9", display: "210m2" },
  { "value": "10", display: "+210m2" },
]


export const LIFT_SIZE = [
  { "value": "0", display: "Kein Lift" },
  { "value": "1", display: "Ja, für 2-3 Personen" },
  { "value": "2", display: "Ja, fur 4-5 Personen" },
  { "value": "3", display: "Ja, für 6+ Personen" },
  { "value": "4", display: "Ja, Warenlift für 10+ Personen" },
]


@Component({
  selector: 'app-inquiry-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class InquiryStep2Component implements OnInit {

  public draftInquiryForm = new FormGroup({
    from_type: new FormControl('', Validators.required),
    from_size_rooms: new FormControl("3", Validators.required),
    from_size_square_meters: new FormControl("2", Validators.required),
    from_size_floors: new FormControl("1", Validators.required),
    from_type_of_elevator: new FormControl("1", Validators.required),

    from_has_more_than_5_meters: new FormControl(false, Validators.required),
    from_way_from_parking_meters: new FormControl("10", Validators.required),

    from_has_stair_steps: new FormControl(false, Validators.required),
    from_number_of_stair_steps: new FormControl("20", Validators.required),

    from_other_difficulties: new FormControl(false, Validators.required),
    from_other_difficulties_description: new FormControl(''),

    service: new FormControl('1', Validators.required),

  });

  environment = environment

  isLoading: boolean = false;
  currentUUID: string = ''

  selectors = {
    number_of_rooms: {
      fieldName: 'Zimmer',
      options: [
        { 'value': "1", 'display': '1' },
        { 'value': "2", 'display': '2' },
        { 'value': "3", 'display': '3' },
        { 'value': "4", 'display': '4' },
        { 'value': "5", 'display': '5' },
        { 'value': "6", 'display': '6' },
        { 'value': "7", 'display': '7' },
        { 'value': "8", 'display': '8' },
        { 'value': "9", 'display': '9' },
        { 'value': "10", 'display': '10' },
      ]
    },
    from_size_square_meters: {
      fieldName: 'Wohnfläche',
      options: FROM_SIZE_SQUARE_METERS
    },
    from_size_floors: {
      fieldName: 'Stockwerke',
      options: [
        { "value": "1", display: "1" },
        { "value": "2", display: "2" },
        { "value": "3", display: "3" },
        { "value": "4", display: "4" },
        { "value": "5", display: "5" },
        { "value": "6", display: "6" },
        { "value": "7", display: "7" },
        { "value": "8", display: "8" },
        { "value": "9", display: "9" },
        { "value": "10", display: "+10" },
      ]
    },

    from_way_from_parking_meters: {
      fieldName: 'Distanz in Meter ca.',
      options: [
        { value: "10", display: "10" },
        { value: "20", display: "20" },
        { value: "30", display: "30" },
        { value: "40", display: "40" },
        { value: "50", display: "50" },
        { value: "60", display: "60" },
        { value: "70", display: "70" },
        { value: "80", display: "80" },
        { value: "90", display: "90" },
        { value: "100", display: "+100" },
      ]
    },
    from_number_of_stair_steps: {
      fieldName: 'Anzahl Stufen ca.',
      options: [
        { value: "10", display: "10" },
        { value: "20", display: "20" },
        { value: "30", display: "30" },
        { value: "40", display: "40" },
        { value: "50", display: "50" },
        { value: "60", display: "60" },
        { value: "70", display: "70" },
        { value: "80", display: "80" },
        { value: "90", display: "90" },
        { value: "100", display: "+100" },
      ]
    },
  }

  constructor(
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private publicInquiryService: PublicInquiryService
  ) {
  }

  async ngOnInit() {
    this.draftInquiryForm.get('service')?.valueChanges.subscribe((value) => {
      const service = this.draftInquiryForm.get('service');
      if (service && service.value) {
        this.publicInquiryService.inquiryServiceType$.next(service.value as InquiryServiceType)
      }
    });

    const currentUUID = localStorage.getItem('currentInquiryUUID');
    if (currentUUID) {
      this.currentUUID = currentUUID;
      const response = await lastValueFrom(this.publicInquiryService.getEnquiry(this.currentUUID))
      this.draftInquiryForm.patchValue(response);
    } else {
      this.router.navigate(['/inquiry/1'])
    }
  }

  async onSubmit() {
    this.draftInquiryForm.markAllAsTouched()
    if (!this.draftInquiryForm.valid) {
      outputErrors(this.draftInquiryForm)
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
   return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.updateEnquiry(this.currentUUID, this.draftInquiryForm.value))
      this.spinnerService.hide()
      if (response.service == InquiryServiceType.MOVE) {
        this.router.navigate(['/inquiry/4']);
      } else {
        this.router.navigate(['/inquiry/3']);
      }
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.isLoading = false;
    }
  }

}
