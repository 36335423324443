import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from './url-constants.service';
import { Observable } from 'rxjs';
import { MovLineOrderStateType } from '../interfaces/order.interface';
import { IDetailedInquiry } from '../interfaces/inquiry.interface';
import { Params } from '@angular/router';


export interface IOfferObject {

  id: number;
  uuid: string;

  created_at: Date,
  updated_at: Date,

  price_move: number;
  price_montage: number;
  price_cleaning: number;
  price_disposal: number;
  price_service_mount_lamps: number;
  price_service_floorliner: number;
  price_service_boxes: number;
  price_services_clothes_boxes: number;
  price_services_furniture_lift: number;

  organization_object?: any;

  inquiry_object: IDetailedInquiry;
}

export interface IOrderObject {
  id: number;
  uuid: string;

  price: number;
  status: number,
  due_date: string;
  offer_object: IOfferObject;
}


export interface IRating {
  created_at: string;
  updated_at: string;

  rating: number;
  text: string;
  author: string;
  order?: number
  order_object?: IOrderObject
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {




  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  getStatistics(from_date: any, to_date: any) {
    return this.http.get<any>(this._urls.MY_STATISTICS, {
      params: { from_date, to_date }
    })
  }

  getNextWorks(from_date: any, to_date: any) {
    return this.http.get<any>(this._urls.MY_NEXT_WORKS, {
      params: { from_date, to_date }
    })
  }

  getMyRatings() {
    return this.http.get<any>(this._urls.MY_RATINGS)
  }

  getOrders() {
    return this.http.get<any>(this._urls.MY_ORDERS)
  }

  getOrderByUUID(orderUUID: string): Observable<IOrderObject> {
    return this.http.get<IOrderObject>(this._urls.MY_ORDER_BY_UUID(orderUUID))
  }

  createBid(inquiryUUID: string, val: any) {
    return this.http.post(this._urls.MY_OFFERS, {
      inquiry: inquiryUUID,
      ...val
    })
  }

  getOffers(queryParams: Params = {}): Observable<any> {
    return this.http.get(this._urls.MY_OFFERS, { params: queryParams })
  }

  markOrderAsDone(orderUUID: string): Observable<unknown> {
    return this.http.patch(this._urls.MY_ORDER_BY_UUID_MARK_DONE(orderUUID), { status: MovLineOrderStateType.DONE })
  }

  getComments(entityUUID: string, params: Params = {}): Observable<any[]> {
    return this.http.get<any[]>(this._urls.MY_COMMENTS_BY_ENTITY_UUID(entityUUID), { params: params });
  }

  addComment(entityUUID: string, data: Params = {}): Observable<any> {
    return this.http.post<any>(this._urls.MY_COMMENTS_BY_ENTITY_UUID(entityUUID), data);
  }

}
