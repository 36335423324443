import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Menu, MenuItem } from '../main/main.component';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
// import { ISubscriptionEnum } from 'projects/webapp/app/interfaces/organization.interface';

@Component({
  selector: 'app-menu-item',
  templateUrl: 'menu-item.component.html',
  styleUrls: ['./menu-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent {

  @Input() menu: Menu = [];

  @Output() elementClicked = new EventEmitter<MenuItem>()

  constructor(
    public organizationsService: OrganizationsService,
  ) {
  }

  onClickItem(item: MenuItem) {
    this.elementClicked.emit(item);
  }
}
