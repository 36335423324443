import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { IOrganization } from '../interfaces/organization.interface';


export interface ISiteKey {
  id?: number;
  name: string;
  token?: string;
  created_at: string;
  updated_at: string;
  active: boolean;
}

export interface IHub {
  id?: number;
  uuid?: string;

  created_at?: string;
  updated_at?: string;

  name: string;
  address: string;
  location: string;

  is_deleted?: boolean;
}


const MOVLINE_ORG_PREFERENCES = 'MOVLINE_ORG_PREFERENCES';


@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  ownOrg$ = new BehaviorSubject<IOrganization | null>(null);

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  initService() {
    this.loadCachedPreferences();
    this.getOwnOrganization().subscribe()
  }

  loadCachedPreferences(): void {
    const cache = localStorage.getItem(MOVLINE_ORG_PREFERENCES);
    if (!!cache) {
      this.ownOrg$.next(JSON.parse(cache));
    }
  }

  cacheOrgPreferences(user: IOrganization): void {
    localStorage.setItem(MOVLINE_ORG_PREFERENCES, JSON.stringify(user));
  }

  getPricing() {
    return this.http.get<any>(this._urls.ORGANIZATION_PRICING)
  }

  updatePricing(data: any) {
    return this.http.patch<any>(this._urls.ORGANIZATION_PRICING, data)
  }

  public getOrganizations(params: {} = {}): Observable<IOrganization[]> {
    return this.http.get<IOrganization[]>(this._urls.ORGANIZATIONS, { params })
  }

  public getOwnOrganization(): Observable<IOrganization> {
    return this.http
      .get<IOrganization>(this._urls.OWN_ORGANIZATION)
      .pipe(
        tap(
          (response) => {
            this.ownOrg$.next(response);
            this.cacheOrgPreferences(response);
          },
          (error) => {
            console.error(error);
          }
        ),
        map((response) => response),
      );
  }

  public updateOwnOrganization(organization: IOrganization): Observable<IOrganization> {
    return this.http.patch<IOrganization>(this._urls.OWN_ORGANIZATION, organization)
  }

}
