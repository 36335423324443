import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import * as Sentry from "@sentry/angular-ivy";
import { environment } from './app/environments/environment';
import { versions } from 'projects/versions';

if (environment.envName == 'prod') {

  Sentry.init({
    dsn: "https://2ca0666b2d01ed354de08cf1eeacb11c@o330358.ingest.sentry.io/4506529428406272",
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['localhost:4200', 'https://app.movline24.ch'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: environment.envName,
    tracesSampleRate: 0.1,
    beforeSend: (event) => {
      const exception = event.exception?.values && event.exception?.values[0]
      if (exception?.value === 'Non-Error exception captured with keys: currentTarget, isTrusted, target, type') {
        return null;
      }
      return event;
    }
  });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    console.log(`Bootstrap success: ${versions.hash}, ${versions.date}`);
  })
  .catch(err => console.error(err));
