import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { IAdminDetailedUser } from 'projects/webapp/app/interfaces/user.interface';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-admin-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class AdminUserDetailsComponent implements OnInit {
  isLoading = true;

  userUUID: string | null = null;
  user: IAdminDetailedUser | null = null;
  environment = environment

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      if (!!params && params['userUUID']) {
        this.userUUID = params['userUUID'];
        this.refresh();
      }
    })
  }

  async refresh() {
    if (this.userUUID) {
      try {
        this.spinnerService.show()
        this.isLoading = true;
        this.user = await lastValueFrom(this.adminService.getUser(this.userUUID))
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide()
        this.isLoading = false;
      }
    }
  }

  async toggleLock() {
    if (this.userUUID) {
      const a = this.user as any
      a.is_active = !a?.is_active
      await lastValueFrom(this.adminService.updateUser(this.userUUID, a))
      this.toastrService.info('updated')
      this.refresh()
    }
  }

  async onChangeOrgProperty(property: string, event: MatCheckboxChange) {
    if (this.userUUID && this.user && this.user.hasOwnProperty(property)) {
      const a = this.user as any
      a[property] = event.checked
      await lastValueFrom(this.adminService.updateUser(this.userUUID, a))
      this.toastrService.info('updated')
      this.refresh()
    }
  }


}
