export enum MovLineOrderStateType {
  OPEN = 1,
  DONE = 2,
  ARCHIVED = 3,
  DELETED = 4,
}


export interface IOrder {

}


export interface IDetailOrder extends IOrder {
  offer_object: any
  status: MovLineOrderStateType
}



export interface IAdminDetailOrder extends IDetailOrder {
  organization_object: any;
  id: number;
  uuid: string;
}
