import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns'
import { de } from 'date-fns/locale'

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'humanDatePipe' })
export class HumanDatePipe implements PipeTransform {
  constructor( ) {}

  transform(date?: string | Date, addSuffix = true): string {
    if (!!date){
      return formatDistance(new Date(date), new Date(), { locale: de, addSuffix })
    }
    return '-'
  }

}


