import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlConstantsService {

  environment = environment
  baseUrl = this.environment.baseUrl

  public HEALTH_STATUS = `${this.baseUrl}/api/v1/health-status`;

  public CALCULATE_PRICE = `${this.environment.baseUrl}/api/v1/public/calculate-price`;
  public PUBLIC_SHIPMENT_DETAILS = (tracking_uuid: string) => `${this.environment.baseUrl}/api/v1/public/shipments/${tracking_uuid}`;
  public PUBLIC_SHIPMENT_COMMENT = (tracking_uuid: string) => `${this.environment.baseUrl}/api/v1/public/shipments/${tracking_uuid}/comments`;

  public PUBLIC_DELIVERY_SLIP_SHIPMENTS_BY_TRACKING_UUID = (tracking_uuid: string) => `${this.baseUrl}/api/v1/public/shipments/${tracking_uuid}/delivery-slip-pdf`;

  public CHECK_NEW_EMAIL = `${this.environment.baseUrl}/api/v1/public/check-new-email`;
  public CONTACT_REQUEST = `${this.environment.baseUrl}/api/v1/public/contact-request`;

  public USER_LOGIN_EMAIL = `${this.baseUrl}/api/v1/auth/login`;
  public USER_REGISTER_EMAIL = `${this.baseUrl}/api/v1/auth/register`;
  public USER_REFRESH_TOKENS = `${this.baseUrl}/api/v1/auth/token-refresh`;
  public USER_FORGET_PASSWORD = `${this.baseUrl}/api/v1/auth/forgot-password`;
  public USER_RESET_PASSWORD = `${this.baseUrl}/api/v1/auth/reset-password`;
  public USER_VERIFY_EMAIL = `${this.baseUrl}/api/v1/public/validate-email`;

  public ORGANIZATIONS = `${this.baseUrl}/api/v1/organizations/all`;
  public OWN_ORGANIZATION = `${this.baseUrl}/api/v1/organizations/mine`;
  public OWN_ORGANIZATION_LOGO = `${this.baseUrl}/api/v1/organizations/mine/logo`;

  public ORGANIZATION_PRICING = `${this.baseUrl}/api/v1/organizations/pricing`;

  public MY_INQUIRIES = `${this.baseUrl}/api/v1/inquiries`;
  public INQUIRY_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/inquiries/${uuid}`;
  public PUBLIC_INQUIRIES = `${this.baseUrl}/api/v1/public/inquiries`;
  public PUBLIC_RATING = `${this.baseUrl}/api/v1/public/rating`;
  public PUBLIC_RATING_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/public/rating/${uuid}`;
  public PUBLIC_INQUIRIES_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/public/inquiries/${uuid}`;
  public PUBLIC_ORDERS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/public/orders/${uuid}`;
  public PUBLIC_INQUIRY_OFFERS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/public/inquiries/${uuid}/offers`;

  public MY_STATISTICS = `${this.baseUrl}/api/v1/dashboard/statistics`;
  public MY_RATINGS = `${this.baseUrl}/api/v1/ratings`;
  public MY_NEXT_WORKS = `${this.baseUrl}/api/v1/dashboard/next-works`;
  public MY_ORDERS = `${this.baseUrl}/api/v1/orders`;
  public MY_ORDER_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/orders/${uuid}`;
  public MY_ORDER_BY_UUID_MARK_DONE = (uuid: string) => `${this.baseUrl}/api/v1/orders/${uuid}/mark-done`;

  public MY_OFFERS = `${this.baseUrl}/api/v1/offers/`;

  public MY_COMMENTS_BY_ENTITY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/entities/${uuid}/comments`;

  public USERS = `${this.baseUrl}/api/v1/users/`;
  public OWN_USER = `${this.baseUrl}/api/v1/users/me`;
  public OWN_AVATAR = `${this.baseUrl}/api/v1/users/avatar`;
  public OWN_PUSH_TOKENS = `${this.baseUrl}/api/v1/users/push-token`;
  public OWN_PUSH_TOKEN = (token: string) => `${this.baseUrl}/api/v1/users/push-token/${token}`;

  public OWN_KEYS = `${this.baseUrl}/api/v1/organizations/keys`;
  public OWN_KEYS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/organizations/keys/${uuid}`;


  public USERS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/users/${uuid}`;
  public USER_PASSWORD_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/users/${uuid}/password`;

  public CLIENTS = `${this.baseUrl}/api/v1/clients/`;
  public CLIENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/clients/${uuid}`;

  public VEHICLES = `${this.baseUrl}/api/v1/vehicles/`;
  public VEHICLES_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/vehicles/${uuid}`;

  public TOURS = `${this.baseUrl}/api/v1/tours/`;
  public TOURS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/tours/${uuid}`;
  public CLOSE_TOURS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/tours/${uuid}/close`;

  public EVENTS = `${this.baseUrl}/api/v1/events/`;

  public SHIPPER_SHIPMENTS = `${this.baseUrl}/api/v1/shipper-shipments/`;
  public SHIPPER_SHIPMENTS_SEARCH = `${this.baseUrl}/api/v1/shipper-shipments/search`;
  public SHIPPER_SHIPMENTS_MARKET = `${this.baseUrl}/api/v1/shipper-shipments/market-place`;
  public SHIPPER_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/shipper-shipments/${uuid}`;
  public SHIPPER_CANCEL_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/shipper-shipments/${uuid}/cancel`;

  public SHIPPER_MARKET_PLACE_ORG_PRICING = `${this.baseUrl}/api/v1/shipper-shipments/org-pricing`;

  public SHIPPER_COMMENT_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/shipper-shipments/${uuid}/comments`;
  public SHIPPER_COMMENT_BY_UUID_SHIPMENTS_BY_UUID = (uuid: string, commentUUID: string) => `${this.baseUrl}/api/v1/shipper-shipments/${uuid}/comments/${commentUUID}`;

  public CARRIER_SHIPMENTS = `${this.baseUrl}/api/v1/carrier-shipments/`;
  public CARRIER_SHIPMENTS_ASSIGNED = `${this.baseUrl}/api/v1/carrier-shipments/assigned`;
  public CARRIER_SHIPMENTS_FOR_APPROVAL = `${this.baseUrl}/api/v1/carrier-shipments/for-approval`;
  public CARRIER_SHIPMENTS_FOR_DISPATCH = `${this.baseUrl}/api/v1/carrier-shipments/for-dispatch`;
  public CARRIER_SHIPMENTS_FOR_INVOICING = `${this.baseUrl}/api/v1/carrier-shipments/for-invoicing`;
  public CARRIER_SHIPMENTS_FOR_ALERTS = `${this.baseUrl}/api/v1/carrier-shipments/for-alerts`;
  public CARRIER_SHIPMENTS_IN_INVOICING = `${this.baseUrl}/api/v1/carrier-shipments/in-invoicing`;
  public CARRIER_SHIPMENTS_FOR_DASHBOARD = `${this.baseUrl}/api/v1/carrier-shipments/dashboard`;

  public CARRIER_SHIPMENTS_SEARCH = `${this.baseUrl}/api/v1/carrier-shipments/search`;
  public CARRIER_SHIPMENTS_PRICING = `${this.baseUrl}/api/v1/carrier-shipments/pricing`;

  public CARRIER_SHIPMENTS_MARKET_PLACE = `${this.baseUrl}/api/v1/carrier-shipments/market-place`;
  public CARRIER_SHIPMENTS_TAKE_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/market-place/take/${uuid}`;
  public CARRIER_SHIPMENTS_VIEW_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/market-place/view/${uuid}`;

  public CARRIER_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}`;
  public CARRIER_PRICE_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/price`;
  public CARRIER_PRICE_TRANSACTION_SHIPMENTS_BY_UUID = (uuid: string, transactionUUID: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/transactions/${transactionUUID}`;
  public CARRIER_PRICE_TRANSACTION_SURCHARGE_SHIPMENTS_BY_UUID = (uuid: string, transactionUUID: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/transactions/${transactionUUID}/surcharge`;

  public CARRIER_DRIVER_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/assignee`;
  public CARRIER_RELEASE_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/release`;
  public CARRIER_SHIPMENTS_MAKE_INVOICE_READY_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/make-ready-for-invoice`;
  public CARRIER_SELL_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/sell`;
  public CARRIER_DISPATCH_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/dispatch`;
  public CARRIER_CANCEL_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/cancel`;
  public CARRIER_COMMENT_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/comments`;
  public CARRIER_IMAGES_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/images`;
  public CARRIER_PRIVATE_COMMENT_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/private-comments`;
  public CARRIER_COMMENT_BY_UUID_SHIPMENTS_BY_UUID = (uuid: string, commentUUID: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/comments/${commentUUID}`;

  public CARRIER_DELIVERY_SLIP_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/delivery-slip-pdf`;
  public CARRIER_LABEL_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/label-pdf`;

  public CARRIER_DO_PICKUP_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/do-pickup`;
  public CARRIER_DO_DELIVER_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/do-deliver`;
  public CARRIER_DO_SET_AT_HUB_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/carrier-shipments/${uuid}/do-set-at-hub`;

  public ADMIN_IMAGES_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/admin/shipments/${uuid}/images`;
  public ADMIN_PRICE_SHIPMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/admin/shipments/${uuid}/price`;

  public CARRIER_IMPORT_SHIPMENTS = `${this.baseUrl}/api/v1/carrier-shipments/import`;
  public CARRIER_UPLOAD_IMPORT_SHIPMENTS_FILE = `${this.baseUrl}/api/v1/carrier-shipments/upload-import-file`;

  public CARRIER_TOURS_SHIPMENTS = (uuid: string) => `${this.baseUrl}/api/v1/tours/${uuid}/shipments`;

  // TODO: move this api to carrier shipments namespace
  public CARRIER_EXPORT_SHIPMENTS = `${this.baseUrl}/api/v1/exports/shipments`;

  public DOCUMENTS_INVOICE_MANUAL_INVOICE_PREVIEW = `${this.baseUrl}/api/v1/documents/manual-invoice/preview`;
  public DOCUMENTS_INVOICE_MANUAL_INVOICE = `${this.baseUrl}/api/v1/documents/manual-invoice/`;
  public INVOICES = `${this.baseUrl}/api/v1/invoices`;
  public INVOICES_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/invoices/${uuid}`;
  public INVOICE_PDF_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/invoices/${uuid}/pdf`;

  public ADVERTISEMENTS = `${this.baseUrl}/api/v1/advertisements`;
  public ADVERTISEMENTS_BY_UUID = (uuid: string) => `${this.baseUrl}/api/v1/advertisements/${uuid}`;
  public ADVERTISEMENT_BY_UUID_IMAGES = (uuid: string) => `${this.baseUrl}/api/v1/advertisements/${uuid}/images`;
  public ADVERTISEMENT_BY_UUID_IMAGES_BY_UUID = (uuid: string, imageUUID: string) => `${this.baseUrl}/api/v1/advertisements/${uuid}/images/${imageUUID}`;

  public CLIENT_INVOICE_READY_SHIPMENTS = (clientUUID: string) => `${this.baseUrl}/api/v1/clients/${clientUUID}/invoice-ready-shipments`;

  constructor() { }
}
