import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';

@Component({
  selector: 'app-inquiry-success',
  templateUrl: './step_success.component.html',
  styleUrls: ['./step_success.component.scss']
})
export class InquirySuccessComponent implements OnInit {


  constructor(
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private publicInquiryService: PublicInquiryService) {
  }

  ngOnInit(): void {
  }


}
