<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
        <h1>Meine Zahlungen</h1>
      </div>

      <div *ngIf="!isLoading && items.length == 0 ">
        <div class="alert alert-info">Keine Zahlungen aktuell</div>
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
      </div>

      <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">

    </mat-card-content>

  </mat-card>
</div>
