import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'projects/webapp/app/environments/environment';
import { MovLineOrderStateType } from 'projects/webapp/app/interfaces/order.interface';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { DashboardService, IOrderObject } from 'projects/webapp/app/services/dashboard.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  isLoading = false;

  item: IOrderObject | undefined = undefined
  orderUUID: string | null = null

  movLineOrderStateType = MovLineOrderStateType;

  environment = environment

  constructor(
    private title: Title,
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    public authService: AuthService,
  ) {
    this.title.setTitle('Movline24: Detail des Auftrages');
  }

  async ngOnInit() {

    this.route.params.subscribe(params => {
      if (!!params && params['orderUUID']) {
        this.orderUUID = params['orderUUID'];
        this.refresh();
      }
    });
    this.refresh();
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show('order-details')
      if (this.orderUUID){
        this.item = await lastValueFrom(this.dashboardService.getOrderByUUID(this.orderUUID))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide('order-details')
    }
  }

  async onClickDone() {
    if (confirm('Sind Sie sicher, dass dieser Auftrag abgeschlossen wurde?') && this.orderUUID) {
      await lastValueFrom(this.dashboardService.markOrderAsDone(this.orderUUID))
    }
  }

}
