<ngx-spinner [name]="'my-ratings'" [fullScreen]="false"></ngx-spinner>
<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
        <h1>Meine Bewertungen</h1>
      </div>

      <div *ngIf="!isLoading && items.length == 0 ">
        <div class="alert alert-info">Keine Bewertungen aktuell</div>
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
      </div>

      <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
        <thead>
          <td>Id</td>
          <td>Datum</td>
          <td>Author</td>
          <td>Bewertung</td>
          <td>Beschreibung</td>
          <td></td>
        </thead>

        <tbody>
          <tr *ngFor="let item of items">

            <td># {{item.id}}</td>
            <td>{{item.updated_at | humanDatePipe}}</td>
            <td>{{item.author}}</td>
            <td> {{item.rating }} </td>
            <td>{{item.text }}</td>
          </tr>
        </tbody>
      </table>

      <pre *ngIf="environment.envName == 'local_dev'">{{items | json}}</pre>

    </mat-card-content>

  </mat-card>
</div>
