<mat-toolbar color="primary">
  <button (click)="menuToggled.emit(true)" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>

  <button mat-button (click)="navigateDashBoard();">
    <img src="assets/favicon/android-chrome-192x192.png" style="height:36px; border-radius: 5px;" />
  </button>

  <span class="spacer"></span>

  <span class="welcome-text">{{ userName }}</span>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>person_pin</mat-icon>
  </button>

  <mat-menu #menu="matMenu">

    <button mat-menu-item (click)="navigateToProfile();">
      <span>Mein Profil</span>
    </button>

    <button mat-menu-item (click)="navigateToCompany();">
      <span>Meine Firma</span>
    </button>

    <button mat-menu-item (click)="navigateToAbout();">
      <span>Über movline24.ch</span>
    </button>

    <button mat-menu-item (click)="logout();">
      <span>Logout</span>
    </button>

    <span mat-menu-item>
      <small style="color: gray;">v{{versions.hash}} - {{versions.date | date: "yyyy-MM-dd HH:mm"}}</small>
    </span>
  </mat-menu>
</mat-toolbar>
