<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1>Alle Aufträge</h1>
      </div>

      <div *ngIf="!isLoading && items.length == 0 ">
        <div class="alert alert-info mt-5">Keine Bewertungen</div>
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
      </div>

      <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
        <thead>
          <td>id</td>
          <td>Von</td>
          <td>Nach</td>
          <td>Status</td>
          <td>Firma</td>
          <td>Umsatz (CHF)</td>
          <td>Zahlung</td>
          <td></td>
        </thead>

        <tbody>
          <tr *ngFor="let item of items">

            <td># {{item.id}}</td>
            <td><span *ngIf="item?.offer_object?.inquiry_object">{{item.offer_object.inquiry_object.from_location}}</span></td>
            <td><span *ngIf="item?.offer_object?.inquiry_object">{{item.offer_object.inquiry_object.to_location }}</span></td>
            <td>
              {{item.status | orderStatePipe}}
            </td>
            <td><span *ngIf="item?.organization_object">{{item.organization_object.name}}</span></td>
            <td>{{item.total_price | thousandsSeparator }}</td>
            <td>{{item.payment | orderPaymentTypePipe }}</td>
            <td>
              <a routerLink="/main/admin/orders/{{item.uuid}}" class="button">Details</a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pre>{{items | json}}</pre> -->
    </mat-card-content>
  </mat-card>
</div>
