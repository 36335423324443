<div class="container" *ngIf="item">
  <div class="mt-2 mb-3 text-center" *ngIf="authService.isAuthenticatedPlatformAdmin()">
    <a mat-flat-button color="accent" routerLink="/main/admin/inquiries/{{item.uuid}}">ADMIN PAGE</a>
  </div>

  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h1>
      <span *ngIf="item.tenant">Direkte </span>
      <span>Anfrage: #{{item.id}}</span>
    </h1>
  </div>
</div>

<app-inquiry-details-card [item]="item"></app-inquiry-details-card>

<div class="container" *ngIf="item">
  <hr>
  <div *ngIf="this.offers && this.offers[0] else notyetoffered">
    <h2>Aktuelle Offerte</h2>
    <div>
      <mat-card class="mt-3">
        <mat-card-content>
          <ul class="co-simple-list">
            <li *ngIf="item.service == inquiryServiceType.MOVE || item.service == inquiryServiceType.MOVE_AND_CLEAN">
              Umzugspreis (CHF): {{offers[0].price_move | thousandsSeparator}}</li>
            <li *ngIf="item.service == inquiryServiceType.MOVE_AND_CLEAN || item.service == inquiryServiceType.CLEAN">
              Reinigung (CHF): {{offers[0].price_cleaning | thousandsSeparator}}</li>
            <li *ngIf="item.services_assembling">Ab- und Aufbau (CHF): {{offers[0].price_montage | thousandsSeparator}}
            </li>
            <li *ngIf="item.service_trash_per_cubic">Entsorgung pro m3 (CHF): {{offers[0].price_disposal |
              thousandsSeparator}}</li>

            <li *ngIf="item.service_mount_lamps">Lampenmontage (CHF): {{offers[0].price_service_mount_lamps |
              thousandsSeparator}}</li>
            <li *ngIf="item.service_floorliner">Floorliner (CHF): {{offers[0].price_service_floorliner |
              thousandsSeparator}}</li>
            <li *ngIf="item.service_boxes">Kisten einpacken (CHF): {{offers[0].price_service_boxes |
              thousandsSeparator}}</li>
            <li *ngIf="item.services_clothes_boxes">Kleiderboxen mieten (CHF): {{offers[0].price_services_clothes_boxes
              | thousandsSeparator}}</li>
            <li *ngIf="item.services_furniture_lift">Möbellift: (CHF): {{offers[0].price_services_furniture_lift |
              thousandsSeparator}}</li>

            <!-- <li style="font-weight: bold;">Total (CHF): {{offers[0].price_move + offers[0].price_montage +
              offers[0].price_cleaning + offers[0].price_disposal | thousandsSeparator}}</li> -->
            <pre *ngIf="environment.envName == 'local_dev'">{{offers[0] | json}}</pre>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
    <hr>
  </div>
  <ng-template #notyetoffered>
    <div class="alert alert-info">Noch nicht offeriert</div>
  </ng-template>

  <div *ngIf="item?.status == inquiryStatus.OPEN_FOR_BIDS">
    <div *ngIf="(this.offers && this.offers[0]) else notyetofferedbutton">
      <button mat-flat-button color="primary" (click)="doOffer()" cdkFocusInitial>
        Offerte anpassen
      </button>
    </div>
    <ng-template #notyetofferedbutton>
      <button mat-flat-button color="primary" (click)="doOffer()" cdkFocusInitial *ngIf="!item.tenant">
        Offerieren
      </button>
      <button mat-flat-button color="primary" (click)="doOffer()" cdkFocusInitial *ngIf="!!item.tenant">
        Annehmen
      </button>
    </ng-template>
  </div>
  <pre *ngIf="environment.envName == 'local_dev'">
    {{item | json}}
  </pre>

</div>
