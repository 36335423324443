import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDetailedInquiry, InquiryStatus } from 'projects/webapp/app/interfaces/inquiry.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { InquiriesService } from 'projects/webapp/app/services/inquiries.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { generateRoomFormGroup } from '../../public/inquiry/raw/step5/step5.component';
import { outputErrors } from 'projects/webapp/app/utils';


@Component({
  selector: 'app-admin-edit-inquiry-inventory',
  templateUrl: './admin-edit-inquiry-inventory.component.html',
  styleUrls: ['./admin-edit-inquiry-inventory.component.scss']
})
export class AdminEditInquiryInventoryComponent implements OnInit {

  public inquiryForm = new FormGroup({
    inventar: new FormArray([generateRoomFormGroup()]),
  });

  public roomItemForms: FormArray = this.inquiryForm.get('inventar') as FormArray;

  public isLoading = false;
  item: IDetailedInquiry | null = null
  offers: any | null = null
  inquiryUUID: string | null = null;

  inquiryStatus = InquiryStatus;

  environment = environment

  constructor(
    private title: Title,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    public router: Router,
  ) { }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Inventar Editieren`);
    this.route.params.subscribe(params => {
      if (!!params && params['inquiryUUID']) {
        this.inquiryUUID = params['inquiryUUID'];
        this.refresh();
      }
    });
  }

  async refresh() {
    try {
      this.spinnerService.show();
      if (this.inquiryUUID) {
        this.item = await lastValueFrom(this.adminService.getInquiry(this.inquiryUUID))

        while (this.roomItemForms.length) {
          this.roomItemForms.removeAt(0);
        }

        this.item.inventar.forEach(room => {
          const roomGroup = generateRoomFormGroup();
          roomGroup.patchValue({
            name: room.name
          });

          const furnitureArray = roomGroup.get('furniture') as FormArray;

          while (furnitureArray.length) {
            furnitureArray.removeAt(0);
          }

          room.furniture.forEach(furniture => {
            furnitureArray.push(new FormGroup({
              name: new FormControl(furniture.name, Validators.required),
              number: new FormControl(furniture.number, Validators.required),
              details: new FormControl(furniture.details)
            }));
          });

          this.roomItemForms.push(roomGroup);
        });
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }

  async onAdminReleaseToInquirer() {
    if (confirm("Sind Sie sicher, dass Sie die Gebotsrunde abschliessen wollen? Es wird eine E-Mail an den Kunde geschickt mit den aktuellen Angeboten") && this.inquiryUUID && this.item) {
      let data = this.item
      data.status = InquiryStatus.WAITING_FOR_ACCEPTANCE
      this.item = await lastValueFrom(this.adminService.updateInquiry(this.inquiryUUID, data));
    }
  }

  async save() {
    this.inquiryForm.markAllAsTouched()
    if (!this.inquiryForm.valid) {
      console.log(this.inquiryForm.errors)
      outputErrors(this.inquiryForm);
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    if (confirm("Sind Sie sicher, dass Sie die Anfrage bearbeiten wollen?") && this.inquiryUUID && this.item) {
      this.item = await lastValueFrom(this.adminService.updateInquiry(this.inquiryUUID, this.inquiryForm.value));
      setTimeout(() => {
        this.router.navigate(['/main/admin/inquiries/' + this.inquiryUUID])
      }, 500)
    }
  }

  cancel() {
    this.router.navigate(['..'])
  }

  onAddNewRoom() {
    const roomItemsCount = this.roomItemForms.controls.length;
    const roomItemForm = generateRoomFormGroup();
    this.roomItemForms.insert(roomItemsCount, roomItemForm);
    return roomItemForm
  }

  removeRoomItem($event: number) {
    if (this.roomItemForms) {
      this.roomItemForms.removeAt($event);
    }
  }

}
