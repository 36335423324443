import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { versions } from 'projects/versions';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  versions = versions;
  isLoading = false;

  constructor(
    private router: Router,
    private title: Title,
    public organizationsService: OrganizationsService,
    public usersService: UsersService,
  ) {
    this.title.setTitle('movline24: Dashboard')
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true;
      // this.dashboardNumbers = await lastValueFrom(this.shipperShipmentService.getDashboardNumbers())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

  navigateToMarketPlace() {
    this.router.navigate(['/main/shipper/market-place'])
  }

}
