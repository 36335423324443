<div class="kachel" *ngIf="!!item">
  <div class="div-block-8">
    <h3 class="heading-2" *ngIf="item.service == inquiryServiceType.CLEAN">REINIGUNGSANFRAGE</h3>
    <h3 class="heading-2" *ngIf="item.service != inquiryServiceType.CLEAN">UMZUGSANFRAGE</h3>
    <p *ngIf="item.service == inquiryServiceType.CLEAN" class="paragraph-10">{{item.cleaning_date | date: 'dd-MM-yyyy'}}
    </p>
    <p *ngIf="item.service != inquiryServiceType.CLEAN" class="paragraph-10">{{item.moving_date | date: 'dd-MM-yyyy'}}
    </p>

  </div>

  <div *ngIf="item.service != inquiryServiceType.CLEAN">
    <div id="w-node-d3d377b1-8cd2-00cb-9c2e-8ba5ded991e0-b3f9eb46"
      class="w-layout-layout quick-stack-3 wf-layout-layout">
      <div class="w-layout-cell">
        <div class="div-block-9">
          <h4><span class="text-span-2">Von<br></span>{{item.from_zip}} {{item.from_location}}</h4>
        </div>
      </div>
      <div class="w-layout-cell">
        <div class="div-block-9-copy">
          <h4 class="heading-3"><span class="text-span-2">Nach<br></span>{{item.to_zip}} {{item.to_location}}</h4>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="item.service == inquiryServiceType.CLEAN">
        <div class="div-block-9">
          <h4><span class="text-span-2">Objekt in<br></span>{{item.from_zip}} {{item.from_location}}</h4>
        </div>
  </div>

  <div class="div-block-10">
    <div id="w-node-_81637dff-9a52-aa6e-9c97-c91a1a6062c3-b3f9eb46"
      class="w-layout-layout quick-stack-4 wf-layout-layout">
      <div id="w-node-_81637dff-9a52-aa6e-9c97-c91a1a6062c4-b3f9eb46" class="w-layout-cell cell-5">
        <p class="paragraph-11">Art<br><strong>{{item.from_type | propertyTypePipe}}</strong></p>
      </div>
      <div id="w-node-_81637dff-9a52-aa6e-9c97-c91a1a6062c5-b3f9eb46" class="w-layout-cell">
        <p class="paragraph-11">Zimmer<br>‍<strong>{{item.from_size_rooms}} Zimmer</strong></p>
      </div>
      <div id="w-node-_294f6318-6dd3-d6cb-a7e1-3daad9772692-b3f9eb46" class="w-layout-cell">
        <p class="paragraph-11">Quadratmeter<br>‍<strong>{{item.from_size_square_meters | sizeSquareTypePipe}}</strong>
        </p>
      </div>
      <div id="w-node-_4c674add-2730-6bfc-b648-f8d26a505151-b3f9eb46" class="w-layout-cell">
        <p class="paragraph-11">Lift<br>‍<strong>{{item.from_type_of_elevator}} Personen</strong></p>
      </div>
      <div id="w-node-_38502a95-f634-7c1d-ac07-9033875ad1a9-b3f9eb46" class="w-layout-cell">
        <p class="paragraph-11">Möbelstücke<br>‍<strong>{{numberOfFurniture}} Stück</strong></p>
      </div>
      <div id="w-node-b9e9563f-4b9e-85e8-27a9-0bbb27134de4-b3f9eb46" class="w-layout-cell"></div>
    </div>
  </div>
  <div class="div-block-11">
    <h3 class="heading-4">Extras</h3>
    <div id="w-node-_6dd9eb1c-59b0-572a-8aad-6a2fa0bb4f21-b3f9eb46"
      class="w-layout-layout quick-stack-5 wf-layout-layout">

      <!-- <div id="w-node-_6dd9eb1c-59b0-572a-8aad-6a2fa0bb4f22-b3f9eb46" class="w-layout-cell cell-6">
          <p class="paragraph-12">Entsorgung</p>
        </div>
        <div id="w-node-_6dd9eb1c-59b0-572a-8aad-6a2fa0bb4f23-b3f9eb46" class="w-layout-cell cell-8">
          <p class="paragraph-12">Entsorgung</p>
        </div>
        <div id="w-node-_0b8658a6-206d-a212-194e-f706b628e5ac-b3f9eb46" class="w-layout-cell cell-10">
          <p class="paragraph-12">Entsorgung</p>
        </div>
        <div id="w-node-_15e6f75e-123f-e6f0-6d33-3784701a3949-b3f9eb46" class="w-layout-cell cell-7">
          <p class="paragraph-12">Entsorgung</p>
        </div>
        <div id="w-node-eece777f-6add-6e89-e16e-809a10df8db5-b3f9eb46" class="w-layout-cell cell-11">
          <p class="paragraph-12">Entsorgung</p>
        </div>
        <div id="w-node-_83d4146c-2570-62e2-17e1-0d03b302fc55-b3f9eb46" class="w-layout-cell cell-9">
          <p class="paragraph-12">Entsorgung</p>
        </div> -->

      <div *ngFor="let service of services">

        <div id="w-node-_6dd9eb1c-59b0-572a-8aad-6a2fa0bb4f22-b3f9eb46" class="w-layout-cell cell-6"
          *ngIf="hasService(service)">
          <p class="paragraph-12">{{serviceName(service)}}</p>
        </div>

        <!-- <div class="div-31">
          <div class="div-32">
            <div class="div-33 tags" *ngIf="hasService(service)">{{serviceName(service)}}</div>
          </div>
        </div> -->
      </div>


    </div>
  </div>
  <div class="div-block-12">
    <a [routerLink]="[]" class="button-2 w-button" (click)="navigateToDetails()">Details</a>
  </div>
</div>

<pre *ngIf="environment.envName == 'local_dev'">{{item | json}}</pre>
