import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicLayoutComponent implements OnInit {
  constructor(
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit(){
  }

  cancelLoading() {
    this.spinnerService.hide()
  }
}
