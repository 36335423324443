import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { lastValueFrom } from "rxjs";
import { DashboardService } from "../../services/dashboard.service";
import { format } from 'date-fns';


@Component({
  selector: "app-next-works-card",
  templateUrl: 'next-works-card.component.html',
  styleUrls: ['./next-works-card.component.scss']
})
export class NextWorksComponent implements OnInit {

  dayList: any = null
  nextWorks: any = []

  constructor(
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService
  ) {
    // this.dayList = this.generateDayList();
    // console.log(this.dayList);
  }

  async ngOnInit() {
    this.refresh();
  }
  async refresh() {
    try {
      this.spinnerService.show('next-works');

      const today = new Date();
      const inSevenDays = new Date(today);
      inSevenDays.setDate(today.getDate() + 7);

      const start = this.formatDate(today)
      const end = this.formatDate(inSevenDays)
      this.nextWorks = await lastValueFrom(this.dashboardService.getNextWorks(start, end))
      this.dayList = this.generateDayList();

      this.nextWorks.forEach((work: any)=> {
        this.dayList.forEach((day: any) => {

          console.log(`work.due_date: ${this.formatDate(new Date(work.due_date))}`)
          console.log(`day.date: ${this.formatDate(new Date(day.date))}`)

          if (this.formatDate(new Date(work.due_date)) == this.formatDate(new Date(day.date))) {
            if (day['work_items']) {
              day['work_items'].push(work)
            } else {
              day['work_items'] = [work]
            }
          }
        });
      });
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('next-works')
    }
  }

  formatDate(date: Date): string {
    // Use date-fns to format the date
    return format(date, 'yyyy-MM-dd');
  }

  generateDayList() {
    const daysOfWeekGerman = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const today = new Date();
    const dayList = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
      const dayOfWeek = daysOfWeekGerman[date.getDay()];
      const dayOfMonth = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based, so we add 1
      const year = date.getFullYear();

      const formattedDate = `${dayOfWeek}, ${dayOfMonth}.${month}.${year}`;
      dayList.push({
        date: date,
        formatted_date: formattedDate
      });
    }

    return dayList;
  }

}

