<div class="container">

  <div *ngIf="!isLoading && items.length == 0 ">
    <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
    <div class="alert alert-info mt-2">Keine Anfragen</div>
  </div>


  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h1>Anfragen</h1>
  </div>

  <table class="table table-striped" style="width: 100%" *ngIf="!!items && items.length > 0">
    <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Wann</th>
        <th scope="col">Status</th>
        <th scope="col">Von</th>
        <th scope="col">Nach</th>
        <th scope="col">Offerten</th>
        <th scope="col"></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of items">
        <td>
          #{{item.id}}
        </td>
        <td>
          {{item.updated_at | humanDatePipe}}
        </td>
        <td>
          {{item.status | inquiryStatusPipe}}
          <span *ngIf="item.tenant">
            <br>
            <span class="badge badge-warning" style="background-color: yellowgreen;">DIREKT</span>
          </span>
        </td>

        <td>
          {{item.from_address }}
          <br>
          {{item.from_zip }} {{item.from_location }}
        </td>
        <td>
          {{item.to_address }}
          <br>
          {{item.to_zip }} {{item.to_location }}
        </td>
        <td>
          {{item.offer_objects.length}}
        </td>
        <td>
          <button mat-flat-button color="primary" (click)="onClickDetails(item.uuid)">Details</button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="alert alert-info">
    <h5>
      Anfrage Status sind:
    </h5>
    <pre>
      DRAFT -> user hat nur angefangen aber hat abgebrochen
      OPEN -> user hat anfrage angeschickt und wartet auf freigabe
      ACCEPTED  -> admin hat die Anfrage akzeptiert
      OPEN_FOR_BIDS -> Anfrage kann von Firmen gesehen und Firmen koennen Angebote machen
      ASSIGNED -> Anfrage wurde an eine Firma zugewiesen
      DONE -> Auftrag wurde bearbeitet
    </pre>
  </div>
</div>
