<ngx-spinner [name]="'load-order'" [fullScreen]="false">Loading...</ngx-spinner>

<section class="header">
  <div class="div-block-4"><img src="/assets/logos/movline-logo-white.png" loading="lazy" width="207" alt=""></div>
</section>

<div *ngIf="!isLoading && !item.offer_object" class="alert alert-warning m-3">
  Auftrag konnte nicht geladen werden.
</div>

<div *ngIf="item && item.offer_object && item.offer_object.inquiry_object">

  <div class="container mb-4">
    <h1>Auftragsbestätigung</h1>
  </div>

  <app-inquiry-details-card [showCommentSections]="false"
    [item]="item.offer_object.inquiry_object"></app-inquiry-details-card>

  <div class="container">
    <hr>
    <h2>Konditionen</h2>

    <div class="row">
      <div class="col-12">
        <mat-card class="mt-3">
          <mat-card-content>
            <ul class="co-simple-list">
              <li [ngClass]="{ 'strike-through': !item.want_move  }">Umzugspreis (CHF): {{item.price_move |
                thousandsSeparator}}</li>
              <li [ngClass]="{ 'strike-through': !item.want_cleaning  }">Reinigung (CHF): {{item.price_cleaning |
                thousandsSeparator}}</li>
              <li [ngClass]="{ 'strike-through': !item.want_montage  }">Ab- und Aufbau (CHF): {{item.price_montage |
                thousandsSeparator}}</li>
              <li [ngClass]="{ 'strike-through': !item.want_disposal  }">Entsorgung pro m3 (CHF): {{item.price_disposal
                | thousandsSeparator}}</li>
              <li [ngClass]="{ 'strike-through': !item.want_service_mount_lamps  }">Lampenmontage (CHF):
                {{item.price_service_mount_lamps | thousandsSeparator}}</li>
              <li [ngClass]="{ 'strike-through': !item.want_service_floorliner  }">Floorliner (CHF):
                {{item.price_service_floorliner | thousandsSeparator}}</li>
              <li [ngClass]="{ 'strike-through': !item.want_service_boxes  }">Kisten einpacken (CHF):
                {{item.price_service_boxes | thousandsSeparator}}</li>
              <li [ngClass]="{ 'strike-through': !item.want_services_clothes_boxes  }">Kleiderboxen mieten (CHF):
                {{item.price_services_clothes_boxes | thousandsSeparator}}</li>
              <li [ngClass]="{ 'strike-through': !item.want_services_furniture_lift  }">Möbellift: (CHF):
                {{item.price_services_furniture_lift | thousandsSeparator}}</li>

            </ul>
            <hr>
            <ul class="co-simple-list">
              <li>Zahlungskonditionen (CHF): {{item.payment | orderPaymentTypePipe}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <hr>
    <h2>Kontaktdetails (Auftraggeber / Kunde)</h2>

    <div class="row" *ngIf="!!item && !!item.offer_object && !!item.offer_object.inquiry_object">
      <div class="col-12">
        <mat-card class="mt-3">
          <mat-card-content>
            <ul class="co-simple-list">
              <li>E-Mail: {{item.offer_object.inquiry_object.email}}</li>
              <li>Anrede: {{item.offer_object.inquiry_object.gender | genderPipe}}</li>
              <li>Vorname: {{item.offer_object.inquiry_object.first_name}}</li>
              <li>Nachname: {{item.offer_object.inquiry_object.last_name}}</li>
              <li>Telefon: {{item.offer_object.inquiry_object.phone || "-"}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <hr>
    <h2>Kontaktdetails (Auftragnehmer / Firma)</h2>

    <div class="row" *ngIf="!!item && !!item.offer_object && !!item.offer_object.inquiry_object">
      <div class="col-12">
        <mat-card class="mt-3">
          <mat-card-content>
            <ul class="co-simple-list">
              <li>Firma: {{item.organization_object.company_name}}</li>
              <li>E-Mail: {{item.organization_object.email}}</li>
              <li>Telefon:   {{item.organization_object.phone || "-"}}</li>
              <li>Website:    {{item.organization_object.website || '-'}}</li>
              <li *ngIf="item.organization_object.address">Adresse: {{item.organization_object.address}}</li>
              <li *ngIf="item.organization_object.zip || item.organization_object.location">Ortschaft: {{item.organization_object.zip}} {{item.organization_object.location}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <hr>

    <div class="alert alert-info">
      Auftrag #{{item.id}}, Status: {{item.status | orderStatePipe}}
    </div>
  </div>

</div>

<pre *ngIf="environment.envName == 'local_dev'">
    {{item | json}}
</pre>
