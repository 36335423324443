import { DecimalPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';


export const convertToNumberIfString = (value: string | number): number  => {
  if (typeof value === 'string' && !isNaN(Number(value))) {
    return Number(value); // Convert the string to a number if it's a valid number
  }
  return value as any; // If it's already a number or not a valid number, return as is
}

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'pricePipe' })
export class PricePipe implements PipeTransform {
  constructor() { }

  transform(value: any) {
    try {
      const decimalPipe = new DecimalPipe('de-CH');
      return decimalPipe.transform(value, '1.2-2');
    } catch (error) {
      console.error(error)
      return value;
    }
  }
}


@Pipe({
  name: 'thousandsSeparator'
})
export class ThousandsSeparatorPipe implements PipeTransform {
  transform(value: number | string): string {
    value = convertToNumberIfString(value)

    if (isNaN(value)) return '-';
    if (value == null){
      console.error('value is null')
      return '-';
    }

    // Convert number to string and split it by decimal point
    let parts = value.toFixed(2).toString().split('.');

    // Add thousands separator (upticks) using regular expression
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    // Join the parts back together with decimal point
    return parts.join('.');
  }
}
