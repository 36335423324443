import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { versions } from 'projects/versions';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { sleep } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {
  versions = versions;
  isLoading = false;

  token = ''

  constructor(
    private authService: AuthService,
    private router: Router,
    private title: Title,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute

  ) {
    this.title.setTitle('movline24: Validierung der E-Mail')
  }

  ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      if (!!params['tk']) {
        this.token = params['tk']
        await this.onSubmit();
      } else {
        this.router.navigate(['/'])
      }
    })
  }

  async onSubmit() {
    try {
      this.spinner.show()
      await sleep(1000)
      const result = await lastValueFrom(this.authService.verifyEmail(this.token))
      if (!result) {
        console.error('could not verify user')
      }
      this.router.navigate(['/main'])
    } catch (error) {
      console.error(error)
    } finally {
      this.spinner.hide()
    }
  }
}
