import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'projects/webapp/app/environments/environment';
import { IRating } from 'projects/webapp/app/services/dashboard.service';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-company-ratings',
  templateUrl: './company-ratings.component.html',
  styleUrls: ['./company-ratings.component.scss']
})
export class CompanyRatingsComponent implements OnInit {

  companyUUID = ''

  org: any = null

  items: IRating[] = [
  ]
  isLoading = false;
  environment = environment;
  average_rating: any;

  constructor(
    private spinnerService: NgxSpinnerService,
    private publicInquiryService: PublicInquiryService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!!params['tk']) {
        this.companyUUID = params['tk']
        this.refresh()
      }
    });
  }

  async refresh() {
    try {
      this.isLoading=true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.getMyRatings(this.companyUUID));
      this.org = response.organization_object
      this.items = response.ratings
      this.average_rating = response.average_rating

      this.items = this.items.slice(0, 20);
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }
}
