<ngx-spinner [name]="'rate-order'" [fullScreen]="false"></ngx-spinner>



<body class="body">
  <section class="header">
    <div class="div-block-4">
      <a href="https://www.movline24.ch/" target="_blank">
        <img src="/assets/logos/movline-logo-white.png" loading="lazy" width="207" alt="">
      </a>
    </div>
  </section>
  <div class="step-1">
    <h1 class="heading">Auftrag Bewerten</h1>
    <div class="step-1-wrapper">
      <div class="w-form">

        <form id="email-form" class="form" [formGroup]="form">
          <div class="div-block-3">

            <span>Ihr Name</span>
            <input class="text-field-3 w-input" maxlength="256" placeholder="Hans Müller" type="text"
              formControlName="author">

            <span>Bewertungstext</span>
            <textarea class="text-field-3 w-input" rows="7"
            maxlength="256" placeholder="Alles Top!" type="text"
              formControlName="text"></textarea>

            <span>Bewertung</span>
            <input class="text-field-3 w-input" min="1" max="5" placeholder="Bewertung zwischen 1 und 5" type="number"
              formControlName="rating">

          </div>
          <input type="submit" (click)="onSubmit()" data-wait="Please wait..." class="submit-button w-button" [disabled]="isLoading">
        </form>

        <pre *ngIf="environment.envName == 'local_dev'">{{form.value | json}}</pre>

      </div>
    </div>
  </div>

</body>
