<div class="container" *ngIf="!isLoading && items.length == 0">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
        <h1>Neue Anfragen</h1>
      </div>

      <div>
        <div class="alert alert-info">Keine Anfragen aktuell</div>
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
      </div>

    </mat-card-content>
  </mat-card>

</div>

<div class="container" *ngIf="!isLoading && items.length >= 1">
  <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
    <h1>Neue Anfragen</h1>
  </div>

  <div class="container">
    <div class="row">

      <div *ngIf="!isLoading && items.length == 0 ">
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
        <div class="alert alert-info mt-2">Keine Bewertungen</div>
      </div>

      <div class="col-xxl-6" *ngFor="let item of items">
        <div class="mb-4">
          <inquiry-card-component [item]="item" (onClickDetails)="onClickDetails($event)"></inquiry-card-component>
        </div>
      </div>
    </div>
  </div>
</div>
