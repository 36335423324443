import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { InquiryStatus } from '../interfaces/inquiry.interface';
import { FROM_SIZE_SQUARE_METERS, LIFT_SIZE } from '../pages/public/inquiry/raw/step2/step2.component';
import { MovLineOrderStateType } from '../interfaces/order.interface';


export const getStringFromEnumValue = (enumType: any, value: number): string => {
  for (const key in enumType) {
    if (enumType[key] === value) {
      return key;
    }
  }
  return "Unknown";
}


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'inquiryStatusPipe' })
export class InquiryStatusPipe implements PipeTransform {

  constructor() { }

  transform(status: InquiryStatus): string {
    switch (status) {
      case InquiryStatus.DRAFT:
        return 'Entwurf'
      case InquiryStatus.OPEN:
        return 'Offen'
      case InquiryStatus.ACCEPTED:
        return 'Akzeptiert'
      case InquiryStatus.OPEN_FOR_BIDS:
        return 'Offen für Angebote'
        case InquiryStatus.WAITING_FOR_ACCEPTANCE:
          return 'Warten auf Akzeptanz'
      case InquiryStatus.ASSIGNED:
        return 'In Auftrag gegeben'
      case InquiryStatus.DONE:
        return 'Abgeschlossen'
      case InquiryStatus.ARCHIVED:
        return 'Archiviert'
      case InquiryStatus.DELETED:
        return 'Gelöscht'
      default: {
        console.error(`status ${status} is unknown`);
        return getStringFromEnumValue(InquiryStatus, status)
      }
    }
  }
}


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'orderStatePipe' })
export class OrderStatePipe implements PipeTransform {

  constructor() { }

  transform(status: MovLineOrderStateType): string {
    switch (status) {
      case 1:
        return "Zu erledigen";
      case 2:
        return "Erledigt";
      case 3:
        return "Archiviert";
      case 4:
        return "Gelöscht";
      default: {
        console.error(`status ${status} is unknown`);
        return getStringFromEnumValue(MovLineOrderStateType, status)
      }
    }

  }
}

@Injectable({ providedIn: 'root' })
  @Pipe({ name: 'orderPaymentTypePipe' })
export class OrderPaymentTypePipe implements PipeTransform {

  constructor() { }

  transform(status: any): string {
    switch (status) {
      case 'invoice':
        return "Rechnung";
      case 'cash_at_location':
        return "Barzahlung";
      default: {
        console.error(`status ${status} is unknown`);
        return  ''
      }
    }

  }
}



@Injectable({ providedIn: 'root' })
@Pipe({ name: 'serviceTypePipe' })
export class ServiceTypePipe implements PipeTransform {

  constructor() { }

  transform(service: any) {
    switch (service) {
      case 1:
        return "Umzug und Reinigung";
      case 2:
        return "Nur Umzug";
      case 3:
        return "Nur Reinigung";
      default: {
        console.error(`status ${status} is unknown`);
        return ''
      }
    }
  }
}



@Injectable({ providedIn: 'root' })
@Pipe({ name: 'propertyTypePipe' })
export class PropertyTypePipe implements PipeTransform {
  constructor() { }
  transform(status: any): string {
    switch (status) {
      case 'flat':
        return "Wohnung";
      case 'house':
        return "Haus";
      case 'room':
        return "Raum";
      case 'warehouse':
        return "Lager";
      case 'office':
        return "Büro";
      default: {
        console.error(`status ${status} is unknown`);
        return ''
      }
    }
  }
}


@Injectable({ providedIn: 'root' })
  @Pipe({ name: 'sizeSquareTypePipe' })
export class SizeSquareTypePipe implements PipeTransform {
  constructor() { }
  transform(item: any) {
    for (let index = 0; index < FROM_SIZE_SQUARE_METERS.length; index++) {
      const element = FROM_SIZE_SQUARE_METERS[index];
      if (item == element.value){
        return element.display
      }
    }
    console.error(item)
    return '-'
  }
}


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'elevatorTypePipe' })
export class ElevatorTypePipe implements PipeTransform {
  constructor() { }
  transform(item: any) {
    for (let index = 0; index < LIFT_SIZE.length; index++) {
      const element = LIFT_SIZE[index];
      if (item == element.value) {
        return element.display
      }
    }
    console.error(item)
    return '-'
  }
}
