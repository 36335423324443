import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDetailedInquiry, InquiryStatus } from 'projects/webapp/app/interfaces/inquiry.interface';
import { ActivatedRoute } from '@angular/router';
import { InquiriesService } from 'projects/webapp/app/services/inquiries.service';
import { MatDialog } from '@angular/material/dialog';
import { BidCardModalComponent } from 'projects/webapp/app/components/bid-card-modal/bid-card-modal.component';
import { DashboardService } from 'projects/webapp/app/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { AdminService } from 'projects/webapp/app/services/admin.service';


@Component({
  selector: 'app-admin-inquiry-details',
  templateUrl: './admin-inquiry-details.component.html',
  styleUrls: ['./admin-inquiry-details.component.scss']
})
export class AdminInquiryDetailsComponent implements OnInit {

  public isLoading = false;
  item: IDetailedInquiry | null = null
  offers: any | null = null
  inquiryUUID: string | null = null;

  inquiryStatus = InquiryStatus;

  environment = environment

  constructor(
    private title: Title,
    private inquiriesService: InquiriesService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private dashboardService: DashboardService,
    public dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Details der Anfrage`);
    this.route.params.subscribe(params => {
      if (!!params && params['inquiryUUID']) {
        this.inquiryUUID = params['inquiryUUID'];
        this.refresh();
      }
    });
  }

  async refresh() {
    try {
      this.spinnerService.show();
      if (this.inquiryUUID) {
        this.item = await lastValueFrom(this.adminService.getInquiry(this.inquiryUUID))
        this.offers = await lastValueFrom(this.dashboardService.getOffers({ inquiry: this.inquiryUUID }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }

  doOffer() {
    console.log('do offer');
    this.dialog.open(BidCardModalComponent).afterClosed().subscribe(async (val) => {
      console.log(val);

      if (!!val && this.inquiryUUID) {
        try {
          this.spinnerService.show();
          await lastValueFrom(this.dashboardService.createBid(this.inquiryUUID, val));
          this.toastrService.success('Offerte eingereicht! Sie werden bald weiter informiert!')
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
        }
      }
    })
  }

  async onAdminReleaseInquiry() {
    if (confirm("Sind Sie sicher, dass Sie die Anfrage fuer andere Firmen sichtbar machen wollen?") && this.inquiryUUID && this.item) {
      let data = this.item
      data.status = InquiryStatus.OPEN_FOR_BIDS
      this.item = await lastValueFrom(this.adminService.updateInquiry(this.inquiryUUID, data));
    }
  }

  async onAdminDeleteInquiry() {
    if (confirm("Sind Sie sicher, dass Sie die Anfrage loeschen wollen?") && this.inquiryUUID) {
      this.item = await lastValueFrom(this.adminService.deleteInquiry(this.inquiryUUID));
    }
  }

  async onAdminDeleteOffer(offerUUID: string) {
    if (confirm("Sind Sie sicher, dass Sie diese Offerte loeschen wollen?") && offerUUID) {
      lastValueFrom(this.adminService.deleteOffer(offerUUID));
    }
  }

  async onAdminReleaseToInquirer() {
    if (confirm("Sind Sie sicher, dass Sie die Gebotsrunde abschliessen wollen? Es wird eine E-Mail an den Kunde geschickt mit den aktuellen Angeboten") && this.inquiryUUID && this.item) {
      let data = this.item
      data.status = InquiryStatus.WAITING_FOR_ACCEPTANCE
      this.item = await lastValueFrom(this.adminService.updateInquiry(this.inquiryUUID, data));
    }
  }

}
