import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from './url-constants.service';


@Injectable({
  providedIn: 'root'
})
export class InquiriesService {

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  getInquiries() {
    return this.http.get<any>(this._urls.MY_INQUIRIES)
  }

  getInquiry(uuid: string) {
    return this.http.get<any>(this._urls.INQUIRY_BY_UUID(uuid))
  }
}
