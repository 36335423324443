<div id="w-node-f4c45083-6081-de4a-0ed5-caf2a4acb777-d41280b8" class="w-layout-layout quick-stack wf-layout-layout">
  <div id="w-node-f4c45083-6081-de4a-0ed5-caf2a4acb778-d41280b8" class="w-layout-cell cell-3">
    <a (click)="changePropertyValue('house')" class="link-block w-inline-block" [class.selected-div]="control?.value == 'house'">
      <img src="/assets/inquiry/ph_house.png" loading="lazy" width="45" alt="" class="icon">
      <p class="paragraph-4">Haus</p>
    </a>
  </div>
  <div id="w-node-f4c45083-6081-de4a-0ed5-caf2a4acb779-d41280b8" class="w-layout-cell cell-3">
    <a (click)="changePropertyValue('flat')" class="link-block w-inline-block" [class.selected-div]="control?.value == 'flat'">
      <img src="/assets/inquiry/material-symbols-light_apartment.png" loading="lazy" width="45" alt="" class="icon">
      <p class="paragraph-4">Wohnung</p>
    </a>
  </div>
  <div id="w-node-c3654d22-c292-d44e-b26d-84b63599780a-d41280b8" class="w-layout-cell cell-3">
    <a (click)="changePropertyValue('room')" class="link-block w-inline-block" [class.selected-div]="control?.value == 'room'">
      <img src="/assets/inquiry/cil_room.png" loading="lazy" width="45" alt="" class="icon">
      <p class="paragraph-4 smaller">WG</p>
    </a>
  </div>
  <div id="w-node-_50ce9b6c-b8e9-f066-30d4-6af56ee99613-d41280b8" class="w-layout-cell cell-3">
    <a (click)="changePropertyValue('warehouse')" class="link-block w-inline-block" [class.selected-div]="control?.value == 'warehouse'">
      <img
        src="/assets/inquiry/material-symbols-light_warehouse-outline.png" loading="lazy" width="45" alt=""
        class="icon">
      <p class="paragraph-4">Lager</p>
    </a>
  </div>
  <div id="w-node-f20fa909-09f6-f3dd-8310-83eea2109b04-d41280b8" class="w-layout-cell cell-3">
    <a (click)="changePropertyValue('office')" class="link-block w-inline-block" [class.selected-div]="control?.value == 'office'">
      <img src="/assets/inquiry/ph_office-chair.png" loading="lazy"
        width="45" alt="" class="icon">
      <p class="paragraph-4">Büro</p>
    </a>
  </div>
  <!-- <div id="w-node-_56deadf7-6841-ad03-f759-8586b2f6b677-d41280b8" class="w-layout-cell cell-3"></div> -->
</div>

<!-- <pre *ngIf="control">{{control.value }} </pre> -->
