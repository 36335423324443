import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, lastValueFrom } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { format } from 'date-fns';

@Component({
  selector: 'app-statistics-card',
  templateUrl: './statistics-card.component.html',
  styleUrls: ['./statistics-card.component.scss']
})
export class StatisticsCardComponent implements OnInit {

  campaignOne = new FormGroup({
    start: new FormControl(new Date(this.getSevenDaysAgoDate())),
    end: new FormControl(new Date()),
  });

  statistics: {
    "platform": {
      "inquiries": number,
      "orders": number,
      "turnover": number
    },
    "my_org": {
      "offers": number,
      "orders": number,
      "turnover": number
    }
  } = {
      "platform": {
        "inquiries": 0,
        "orders": 0,
        "turnover": 0
      },
      "my_org": {
        "offers": 0,
        "orders": 0,
        "turnover": 0
      }
    };

  constructor(
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService
  ) { }

  async ngOnInit() {
    this.refresh();
    this.campaignOne.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.refresh();
    });
  }

  getSevenDaysAgoDate() {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    return sevenDaysAgo;
  }

  async refresh() {
    try {
      this.spinnerService.show('statistics');
      if (this.campaignOne.value.start && this.campaignOne.value.end ){
        const start = this.formatDate(this.campaignOne.value.start)
        const end = this.formatDate(this.campaignOne.value.end)
        this.statistics = await lastValueFrom(this.dashboardService.getStatistics(start, end))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('statistics')
    }
  }

  formatDate(date: Date): string {
    // Use date-fns to format the date
    return format(date, 'yyyy-MM-dd');
  }
}
