<div class="container" *ngIf="item">
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h1>Anfrage #{{item.id}}: Inventar Editor</h1>
  </div>
</div>

<div class="container">
  <div class="mx-lg-3">
    <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form" [formGroup]="inquiryForm"
      data-wf-page-id="65ccf2699b4f869a6cf40f2d" data-wf-element-id="3c22ea9e-8587-6cdb-19ca-53e38eea0fb5">

      <div class="main-wrapper">

        <ng-container *ngFor="let roomForm of roomItemForms.controls; index as index">
          <div class="mb-5">
            <app-room [index]="index" [roomForm]="roomForm" [number_of_rooms]="roomItemForms.controls.length"
              (deleteRoom)="removeRoomItem($event)">
            </app-room>
          </div>
        </ng-container>

        <div class="div-block-8">
          <button (click)="onAddNewRoom()" class="button blue w-button"
            *ngIf="roomItemForms.controls.length == 0">erstes
            Zimmer hinzufügen</button>
          <button (click)="onAddNewRoom()" class="button blue w-button"
            *ngIf="roomItemForms.controls.length >= 1">weiteres
            Zimmer hinzufügen</button>
        </div>

      </div>
    </form>
    <pre *ngIf="environment.envName == 'local_dev'">{{inquiryForm.value | json}}</pre>
    <pre *ngIf="environment.envName == 'local_dev'">{{item?.inventar| json}}</pre>
  </div>
</div>

<button mat-flat-button color="secondary" (click)="cancel()">
  Abbrechen
</button>

<button mat-flat-button color="primary" (click)="save()">
  Speichern
</button>
