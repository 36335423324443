import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { debounceTime, distinctUntilChanged, lastValueFrom } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';

export const validateEmail = (email: string) => {
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  isLoading = false;
  form: FormGroup = new FormGroup({
    company_name: new FormControl(''),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),

    accept_terms: new FormControl(false, Validators.requiredTrue),
    re_captcha_response: new FormControl(''),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('[- +()0-9]+.{8,}$')
    ]),
    website: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$")
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
      Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$')
    ]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit(): void {
    const emailForm = this.form.get('email')
    if (emailForm) {
      emailForm.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(email => {
        this.checkEmailAvailability(email);
      });
    }
  }

  checkEmailAvailability(email: string) {
    const emailForm = this.form.get('email')
    if (emailForm) {

      emailForm.setErrors({ emailTaken: null });
      emailForm.setErrors({ userBlocked: null });
      emailForm.setErrors({ orgBlocked: null });

      emailForm.markAsPending();

      this.authService.checkIfEmailAvailable(email).subscribe((result: any) => {
        console.log(result)
        if (result.details == 'user_existing_with_email') {
          emailForm.setErrors({ emailTaken: true });
        } else if (result.details == 'user_is_disabled') {
          emailForm.setErrors({ userBlocked: true });
        } else if (result.details == 'user_is_disabled') {
          emailForm.setErrors({ orgBlocked: true });
        } else {
          emailForm.setErrors(null);
        }

        emailForm.markAsTouched();
        emailForm.markAsPristine();
        // emailForm.markAsUntouched();
      }, (error) => {
        console.error(error);
      });
    }

  }

  async onSubmit() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      console.log(this.form.errors)
      const emailForm = this.form.get('email')
      console.log(emailForm?.errors)
      return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show()
      const token = await lastValueFrom(this.recaptchaV3Service.execute('register'))
      this.form.patchValue({ re_captcha_response: token})
      const success = await lastValueFrom(this.authService.registerUser(this.form.value))
      this.spinnerService.hide()
      if (success) {
        this.router.navigate(['/main'])
      }
    } catch (error) {
      console.error(error);
      this.toastrService.error('Fehler: etwas bei der Registrierung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.isLoading = false;
    }

  }

}
