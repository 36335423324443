import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-public-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss',
  ]
})
export class PublicHeaderComponent implements OnInit, OnDestroy {
  public currentPath = '';
  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.currentPath = location.pathname;
    console.log('Current Path:', this.currentPath);

  }

  ngOnDestroy(): void {

  }
}
