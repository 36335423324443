import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'projects/webapp/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  form: FormGroup = new FormGroup({
    token: new FormControl(''),
    new_password: new FormControl('')
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    console.log('ResetPasswordComponent')
    this.route.queryParams.subscribe(params=>{
      if (!!params['tk']){
        this.form.patchValue({token: params['tk']})
      }
    })
  }

  onSubmit() {
    this.authService.resetPassword(this.form.value).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/main'])
      } else {
        this.form.patchValue({ password: '' })
      }
    });
  }
}
