import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IAdminDetailedInquiry } from 'projects/webapp/app/interfaces/inquiry.interface';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admin-inquiries',
  templateUrl: './admin-inquiries.component.html',
  styleUrls: ['./admin-inquiries.component.scss']
})
export class AdminInquiriesComponent implements OnInit {

  public isLoading = false;
  items: IAdminDetailedInquiry[] = []

  constructor(
    private title: Title,
    private router: Router,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Admin Anfragen`);
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      this.items = await lastValueFrom(this.adminService.getInquiries())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  onClickDetails(uuid: string) {
    this.router.navigate(['/main/admin/inquiries/' + uuid])
  }

}
