import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { versions } from 'projects/versions';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Output() menuToggled = new EventEmitter<boolean>();
  versions = versions;

  userName: string = '';
  canNavigateToOtherPanel = true;

  constructor(
    public authService: AuthService,
    private router: Router,
    public usersService: UsersService
  ) {
    this.userName = this.authService.getUsername() || ''
  }

  ngOnInit(): void {
  }

  navigateToProfile(): void {
    this.router.navigate([`/main/profile`])
  }

  navigateToCompany(): void {
    this.router.navigate([`/main/organization`])
  }

  navigateToClientOverviewManagement(): void {
    console.log('client management');
    this.router.navigate(['/main/clients'])
  }

  navigateToUserActivity(): void {
    console.log('user activity');
    this.router.navigate(['/main/activity'])
  }

  navigateDashBoard(){
    this.router.navigate(['/main/dashboard'])
  }

  navigateToAbout() {
    window.open('https://www.movline24.ch/uber-uns', "_blank");
  }

  navigateToHelp() {
    window.open(environment.uiDomain + '/help', "_blank");
  }

  logout(): void {
    console.log('Logged out');
    this.authService.logout();
    this.router.navigate(['/'])
  }
}
