<div *ngIf="item">

  <div class="container">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <h1>Auftrag #{{item.id}}</h1>

      <a mat-icon-button href="{{environment.apiDomain}}/admin/accounts/movlineorder/{{item.id}}/change/"
        target="_blank">
        <mat-icon>edit</mat-icon>
      </a>

    </div>
  </div>
  <app-inquiry-details-card [item]="item.offer_object.inquiry_object"></app-inquiry-details-card>

  <div class="container">

    <hr>
    <h2>Kontaktdetails (Auftraggeber / Kunde)</h2>

    <div class="row" *ngIf="!!item && !!item.offer_object && !!item.offer_object.inquiry_object">
      <div class="col-12">
        <mat-card class="mt-3">
          <mat-card-content>
            <ul class="co-simple-list">
              <li>E-Mail: {{item.offer_object.inquiry_object.email}}</li>
              <li>Anrede: {{item.offer_object.inquiry_object.gender | genderPipe}}</li>
              <li>Vorname: {{item.offer_object.inquiry_object.first_name}}</li>
              <li>Nachname: {{item.offer_object.inquiry_object.last_name}}</li>
              <li>Telefon: {{item.offer_object.inquiry_object.phone || "-"}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <hr>
    <h2>Kontaktdetails (Auftragnehmer / Firma)</h2>

    <div class="row" *ngIf="!!item && !!item.offer_object && !!item.offer_object.inquiry_object">
      <div class="col-12">
        <mat-card class="mt-3">
          <mat-card-content>
            <ul class="co-simple-list">
              <li>Firma: {{item.organization_object.company_name}}</li>
              <li>E-Mail: {{item.organization_object.email}}</li>
              <li>Telefon: {{item.organization_object.phone || "-"}}</li>
              <li>Website: {{item.organization_object.website || '-'}}</li>
              <li *ngIf="item.organization_object.address">Adresse: {{item.organization_object.address}}</li>
              <li *ngIf="item.organization_object.zip || item.organization_object.location">Ortschaft:
                {{item.organization_object.zip}} {{item.organization_object.location}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <hr>

    <div class="alert alert-info">
      Auftrag #{{item.id}}, Status: {{item.status | orderStatePipe}}
    </div>


    <br>

    <div class="co-buttons-list">
      <button mat-flat-button color="primary" (click)="onClickArchive()"
        *ngIf="item.status==movLineOrderStateType.DONE">
        Auftrag archivieren
      </button>

      <button mat-flat-button color="primary" (click)="onClickDelete()">
        Auftrag löschen
      </button>

    </div>

    <hr>

    <hr>
    <mat-card class="mt-3">
      <mat-card-content>
        <comments-card-component [title]="'Meine Auftragskommentare als Admin (für mich)'" [adminMode]="true" [public]="false"
          [entityUUID]="this.orderUUID"></comments-card-component>
      </mat-card-content>
    </mat-card>

    <hr>
    <mat-card class="mt-3">
      <mat-card-content>
        <comments-card-component [title]="'Meine Auftragskommentare als Admin (für alle)'" [adminMode]="true" [public]="true"
          [entityUUID]="this.orderUUID"></comments-card-component>
      </mat-card-content>
    </mat-card>


  </div>

</div>

<pre *ngIf="environment.envName == 'local_dev'">{{item | json}}</pre>
