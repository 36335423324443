<div class="page-container pb-4 mb-5">

  <div class="container mb-5">

    <mat-card>
      <mat-card-content>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h1>Benutzerprofil</h1>
        </div>

        <form [formGroup]="form" (ngSubmit)="submit()">
          <!-- <div class="d-flex justify-content-center pb-3">
                        <app-avatar formControlName="avatardocument"></app-avatar>
                    </div> -->
          <div class="row mt-4">

            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Vorname</mat-label>
              <input matInput formControlName="first_name">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Nachname</mat-label>
              <input matInput formControlName="last_name">
            </mat-form-field>

          </div>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Telefon</mat-label>
            <input matInput formControlName="phone">
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" readonly>
          </mat-form-field>


          <div class="d-flex justify-content-end">
            <button mat-flat-button color="primary">Speichern</button>
          </div>

        </form>
      </mat-card-content>
    </mat-card>

    <!-- <mat-card class="mt-4">
      <mat-card-content>
        <h1>Meine Präferenzen</h1>

        <form [formGroup]="form" (ngSubmit)="submit()">
          <div style="display: flex; flex-direction: column;">

            <mat-checkbox *ngIf="(usersService.ownUser$ | async)?.permission_market_place"
              formControlName="preference_show_market_place">Zeige Marktplatz im Dashboard</mat-checkbox>
            <mat-checkbox *ngIf="(usersService.ownUser$ | async)?.permission_invoicing"
              formControlName="preference_show_facturation">Zeige Fakturierung im Dashboard</mat-checkbox>
            <mat-checkbox *ngIf="(usersService.ownUser$ | async)?.permission_activity"
              formControlName="preference_show_activity">Zeige Aktivität im Dashboard</mat-checkbox>

            <hr>

            <mat-checkbox formControlName="preference_show_shipments_to_pickup">Zeige Sendungen zur Abholung
              im
              Dashboard</mat-checkbox>
            <mat-checkbox formControlName="preference_show_shipments_in_vehicle">Zeige Sendungen im Fahrzeug
              im
              Dashboard</mat-checkbox>
            <mat-checkbox formControlName="preference_show_shipments_to_deliver">Zeige Sendungen zur
              Auslieferung im Dashboard</mat-checkbox>
          </div>

          <div class="d-flex justify-content-end">
            <button mat-flat-button color="primary">Speichern</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card> -->

    <!-- <pre>{{tokens | json}}</pre> -->

  </div>

</div>
