import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { environment } from 'projects/webapp/app/environments/environment';


@Component({
  selector: 'app-admin-ratings',
  templateUrl: './admin-ratings.component.html',
  styleUrls: ['./admin-ratings.component.scss']
})
export class AdminRatingsListComponent implements OnInit {

  public isLoading = true;
  items: any[] = []

  environment = environment;

  constructor(
    private title: Title,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Admin Ratings`);
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.items = await lastValueFrom(this.adminService.getRatings())
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }
}
