import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDetailedInquiry, InquiryServiceType, InquiryStatus } from 'projects/webapp/app/interfaces/inquiry.interface';
import { InquiriesService } from 'projects/webapp/app/services/inquiries.service';
import { MatDialog } from '@angular/material/dialog';
import { BidCardModalComponent } from 'projects/webapp/app/components/bid-card-modal/bid-card-modal.component';
import { DashboardService } from 'projects/webapp/app/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';


@Component({
  selector: 'app-inquiry-details-card',
  templateUrl: './inquiry-details-card.component.html',
  styleUrls: ['./inquiry-details-card.component.scss']
})
export class InquiryDetailsCardComponent implements OnInit {

  @Input() item: IDetailedInquiry | null = null
  @Input() inquiryUUID: string | null = null;

  @Input() showCommentSections = true;

  inquiryServiceType = InquiryServiceType;

  public isLoading = false;
  inquiryStatus = InquiryStatus;
  environment = environment

  constructor(
    private inquiriesService: InquiriesService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private dashboardService: DashboardService,
    public dialog: MatDialog
  ) { }

  async ngOnInit() {
  }

  async refresh() {
    try {
      this.spinnerService.show();
      if (this.inquiryUUID) {
          this.item = await lastValueFrom(this.inquiriesService.getInquiry(this.inquiryUUID));
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }

  doOffer() {
    console.log('do offer');
    this.dialog.open(BidCardModalComponent).afterClosed().subscribe(async (val) => {
      console.log(val);
      if (!!val && this.inquiryUUID) {
        try {
          this.spinnerService.show();
          await lastValueFrom(this.dashboardService.createBid(this.inquiryUUID, val));
          this.toastrService.success('Offerte eingereicht! Sie werden bald weiter informiert!')
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
        }
      }
    })
  }

}
