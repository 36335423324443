import { IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
  production: true,
  envName: 'prod',
  baseUrl: 'https://api.movline24.ch',
  apiV1: 'api/v1/',
  uiDomain: 'https://app.movline24.ch',
  apiDomain: 'https://api.movline24.ch',

  firebase: {
    apiKey: "AIzaSyAjLVYGBqj1lElLisVS0yXf9eriHh-AqT8",
    authDomain: "movline-23967.firebaseapp.com",
    projectId: "movline-23967",
    storageBucket: "movline-23967.appspot.com",
    messagingSenderId: "822348852511",
    appId: "1:822348852511:web:f0e38316c1bf6b781dd42a",
    measurementId: "G-DREHYN5L7L"
  },
};
