<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-5">
        <h1>Meine Offerten</h1>
      </div>

      <div *ngIf="!isLoading && items.length == 0 ">
        <div class="alert alert-info">Keine Offerten aktuell</div>
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
      </div>

      <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
        <thead>
          <td>Id</td>
          <td>Datum des Auftrages</td>
          <td>Gebotsdatum</td>
          <td>Von</td>
          <td>Nach</td>
          <td>Status</td>
          <td></td>
        </thead>

        <tbody>
          <tr *ngFor="let item of items">

            <td># {{item.id}}</td>
            <td>{{item.inquiry_object.moving_date}}</td>
            <td>{{item.created_at | humanDatePipe}}</td>
            <td>{{item.inquiry_object.from_location}}</td>
            <td>{{item.inquiry_object.to_location}}</td>

            <td>
              {{item.inquiry_object.status | inquiryStatusPipe}}
            </td>
            <td>
              <a routerLink="/main/inquiries/{{item.inquiry_object.uuid}}">Details</a>
            </td>

          </tr>
        </tbody>
      </table>
      <!-- <pre>{{items | json}}</pre> -->
    </mat-card-content>
  </mat-card>

</div>
