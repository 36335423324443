<div>
  <div class="zimmer-wrapper"><label for="Service-w-hlen-2" class="field-label">{{fieldName}}</label>
    <div class="select-wrap">
      <div ms-code-select="prev" class="select-button" (click)="changePropertyValue('selector', 'decrease')" #prev>
        <div class="plus-minus-wrap">
          <div class="minus"></div>
        </div>
      </div>
      <select id="field-2" name="selector" data-name="Field 2" ms-code-select="input" class="select w-select"
         #selector>
        <option *ngFor="let option of optionsList" [value]="option.value">{{option.display}}</option>
      </select>
      <div class="w-embed">
        <style>
          .w-select {
            -webkit-appearance: none;
            -moz-appearance: none;
          }
        </style>
      </div>
      <div ms-code-select="next" class="select-button_plus" #next
        (click)="changePropertyValue('selector', 'increase')">
        <div class="plus-minus-wrap">
          <div class="plus"></div>
          <div class="minus"></div>
        </div>
      </div>
    </div>
  </div>
</div>
