import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { outputErrors } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-rate-order',
  templateUrl: './rate-order.component.html',
  styleUrls: ['./rate-order.component.scss']
})
export class RateOrderComponent implements OnInit {

  public form = new FormGroup({
    author: new FormControl('', Validators.required),
    text: new FormControl('', Validators.required),
    rating: new FormControl(5, Validators.required),
    token: new FormControl('', Validators.required),
  });

  isLoading = true;

  environment = environment;
  constructor(
    private spinnerService: NgxSpinnerService,
    private publicInquiryService: PublicInquiryService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!!params['tk']) {
        this.form.patchValue({ token: params['tk'] });
        this.isLoading = false;
      }
    })
  }

  async onSubmit() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      console.log(this.form.errors)
      outputErrors(this.form)
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.createRating(this.form.value))
      confirm('Vielen Dank! Ihre Bewertung wurde erfolgreich abgegeben.')
      window.location.href = 'https://www.movline24.ch'
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }

}
