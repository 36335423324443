        
<mat-card *ngIf="!showSuccessMessage">
    <mat-card-title>Passwort vergessen</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field style="width:100%">
                    <input type="text" matInput placeholder="E-Mail" formControlName="username">
                </mat-form-field>
            </p>

            <!-- TODO: add recaptcha -->

            <div class="button">
                <button type="submit" mat-flat-button color="primary">Neues Passwort anfordern</button>
            </div>

        </form>
    </mat-card-content>
</mat-card>


<mat-card *ngIf="showSuccessMessage">
    <mat-card-title>Das neue Passwort wurde angefragt.</mat-card-title>
    <mat-card-content>
        <p>Bitte prüfen Sie Ihre Email Box für die weiteren Schritten.</p>
    </mat-card-content>
</mat-card>

